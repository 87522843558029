import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormLabel, ConditionalRender } from './../';

const CustomSelect = styled.select`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 12px 8px 12px 12px;
  height: 40px;
  background: var(--white);
  border: 1px solid var(--neutrals-30);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 5px 0px;
  color: var(--neutrals-60);
  font-size: 16px;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
`;

export const DropDown = props => {
  const [value, setValue] = useState(props.value || 'none');
  const disabled =
    props.disableFirst && (props.disabledOptions || []).length === 0;
  const defaultOption = props.defaultOption || {
    value: 'none',
    label: 'None',
  };
  useEffect(
    () =>
      setValue(
        props.value === null
          ? (props.defaultOption && props.defaultOption.value) || 'none'
          : props.value
      ),
    [props.value] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <>
      {props.label && (
        <FormLabel>
          {props.mandatory ? `${props.label} *` : props.label}
        </FormLabel>
      )}
      <CustomSelect
        onChange={e => {
          props.onChange(e.target.value);
        }}
        value={value}>
        <ConditionalRender
          predicate={!props.options.find(o => o.value === defaultOption.value)}>
          <option
            value={defaultOption.value}
            disabled={disabled}
            hidden={disabled}>
            {defaultOption.label}
          </option>
        </ConditionalRender>
        {props.options.map(({ value, label }) => (
          <option
            key={value}
            value={value}
            disabled={(props.disabledOptions || []).includes(value)}>
            {label}
          </option>
        ))}
      </CustomSelect>
    </>
  );
};
