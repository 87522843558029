import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import React from 'react';
import { Head, ListHeaderContainer } from './styles';
import styled from 'styled-components';
import useMedia from '../../hooks/useMedia';

const ListItem = styled.li`
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 2;
`;

const ListHeader = ({ setSortAttr, sortAttr, options }) => {
  const media = useMedia();
  return (
    <ListItem>
      <Head>
        {options
          .filter(({ condition }) => (condition ? condition(media) : true))
          .map(({ label, value }, index) => (
            <ListHeaderContainer key={index}>
              <h4
                onClick={() =>
                  setSortAttr(a =>
                    a === `${value}-asc` ? `${value}-desc` : `${value}-asc`
                  )
                }>
                {label}
                {(sortAttr === `${value}-asc` && (
                  <IconChevronDown size="15" />
                )) ||
                  (sortAttr === `${value}-desc` && <IconChevronUp size="15" />)}
              </h4>
            </ListHeaderContainer>
          ))}
      </Head>
    </ListItem>
  );
};

export default ListHeader;
