import React, { useState, useContext, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useSelector } from 'react-redux';

const AuthContext = React.createContext(null);

export const AuthProvider = props => {
  const isAuthenticatedAzure = useIsAuthenticated();
  const { inProgress } = useMsal();

  const [isAuthenticated, setIsAuthenticated] = useState(isAuthenticatedAzure);

  useEffect(
    () => setIsAuthenticated(isAuthenticatedAzure),
    [isAuthenticatedAzure]
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authStatus: {
          NONE: inProgress === InteractionStatus.None,
        },
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export const Protected = props => {
  const isSignedIn = useSelector(state => state.user.signedIn);
  return isSignedIn ? props.children : null;
};

export default useAuth;
