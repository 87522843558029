import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Main = styled.button`
  border: none;
  background: ${props =>
    props.disabled ? 'var(--blue-primary-disabled)' : 'var(--blue-primary)'};
  border-radius: 5px;

  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  box-sizing: border-box;

  text-align: center;
  padding: 10px 20px;
  max-width: 220px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover{
    background: var(--blue-secondary);
  }

  color: var(--white);
  a {
    display: flex;
    align-items: center;
    color: var(--white);
    text-decoration: none;
    svg {
      margin-right: 5px;
    }
    &:visited {
      color: var(--white);
    }
  }
`;

const Secondary = styled.button`
  border: 1px solid var(--blue-primary);
  padding: 10px 20px;
  box-sizing: border-box;

  background: var(--white);
  border-radius: 5px;

  font-style: normal;
  font-size: 16px;
  line-height: 21px;

  text-align: center;

  color: var(--blue-primary);
  max-width: 220px;
  cursor: pointer;

  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:hover{
    background: var(--neutrals-10);
  }

  a {
    text-decoration: none;
    color: var(--blue-primary);
    &:visited {
      color: var(--blue-primary);
    }
  }
`;

const Text = styled.button`
  background: none;
  display: flex;
  box-sizing: border-box;

  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  a {
    text-decoration: none;
    color: var(--blue-primary);
    &:visited {
      color: var(--blue-primary);
    }
  }
`;

export const MainButton = props =>
  props.to ? (
    <Main onClick={props.onClickHandler} disabled={props.disabled}>
      <Link to={props.to}>{props.children}</Link>
    </Main>
  ) : (
    <Main onClick={props.onClickHandler} disabled={props.disabled}>
      {props.children}
    </Main>
  );

export const SecondaryButton = props =>
  props.to ? (
    <Secondary onClick={props.onClickHandler} disabled={props.disabled}>
      <Link to={props.to}>{props.children}</Link>
    </Secondary>
  ) : (
    <Secondary onClick={props.onClickHandler} disabled={props.disabled}>
      {props.children}
    </Secondary>
  );

export const TextButton = props =>
  props.to ? (
    <Text onClick={props.onClickHandler} disabled={props.disabled}>
      <Link to={props.to}>{props.children}</Link>
    </Text>
  ) : (
    <Text onClick={props.onClickHandler} disabled={props.disabled}>
      {props.children}
    </Text>
  );
