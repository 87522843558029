import React from 'react';
import { FindUnit, UpcomingActions, SingleOutCustomer } from '../';
import { Container } from './styles';
import styled from 'styled-components';
import { ConditionalRender, MainButton } from '../';
import { useSelector } from 'react-redux';
import { getUnitsOfCurrentCustomer } from '../../redux/selectors';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 10px;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  justify-content: center;
  z-index: 5;

  button {
    padding: 10px;
    margin: 10px;
  }
`;

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: span 2;

  @media (min-width: 576px) {
    grid-column: span 2;
  }

  @media (min-width: 768px) {
    grid-column: span 4;
  }

  @media (min-width: 1024px) {
    grid-column: span 6;
  }
`;

export const Home = () => {
  const unitsOfCurrentCustomer = useSelector(getUnitsOfCurrentCustomer);
  const unitsExists = unitsOfCurrentCustomer.length > 0;
  return (
    <Container>
      <h1>Alfa Laval SnapIT Pilot</h1>
      <Grid>
        <ConditionalRender
          predicate={unitsExists}
          fallback={
            <EmptyList>
              <h3>Let's get started!</h3>
              <h4>Go ahead and add your first unit</h4>
              <ButtonContainer>
                <MainButton to={'/add-unit'}>Add unit</MainButton>
              </ButtonContainer>
            </EmptyList>
          }>
          <UpcomingActions />
          <SingleOutCustomer />
          <FindUnit />
        </ConditionalRender>
      </Grid>
    </Container>
  );
};
