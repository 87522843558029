import { formatISO } from 'date-fns';
import React from 'react';
import { FormLabel } from '..';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components';

const Container = styled.div`
  input {
    font-weight: 400;
    line-height: 24px;
    color: var(--neutrals-60);
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    height: 38px;
    background: var(--white);
    border: 1px solid var(--neutrals-30);
    box-sizing: border-box;
    border-radius: 4px;
    flex-grow: 0;
    margin: 5px 0px;
    width: 100%;
    max-width: 400px;
  }

  .react-datepicker__close-icon {
    margin: 0;
    padding: 0;
    padding-right: 10px;
  }
  .react-datepicker-popper {
    z-index: 6;
  }

  * {
    box-sizing: border-box;
  }
`;

export const DateAndTimePicker = props => (
  <Container>
    {props.label && <FormLabel>{props.label}</FormLabel>}
    <DatePicker
      selected={props.value ? new Date(props.value) : null}
      onChange={value => {
        props.onChange(value ? formatISO(new Date(value)) : null);
      }}
      showTimeSelect
      dateFormat="yyyy-MM-dd HH:mm"
      timeFormat="HH:mm"
      timeIntervals={15}
      isClearable
    />
  </Container>
);
