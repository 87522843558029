import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormLabel, FormDescription, ConditionalRender, TextButton } from './../';
import { IconUpload, IconCircleX } from '@tabler/icons';

const CustomInput = styled.input`
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  hidden: true;
`;

const InputArea = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 156px;
  background: var(--neutrals-10);
  border: 1px dashed var(--neutrals-30);
  box-sizing: border-box;
  border-radius: 4px;

  input:focus-visible {
    border-style: solid;
    border-width: 2px;
    opacity: 1;
    content-visibility:hidden;
`;

const InputAreaLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--neutrals-50);
  margin: 5px 0;
`;

const UploadedFileContainer = styled.div`
  border: 1px solid var(--neutrals-30);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  img {
    height: 75px;
    border-radius: 4px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--neutrals-60);
    padding: 0 20px;
  }
  button {
    background: none;
    border: none;
  }
`;
const UploadedFile = ({ src, onRemoveHandler }) => (
  <UploadedFileContainer>
    <img src={src} alt="" />
    <TextButton onClickHandler={onRemoveHandler}>
      <IconCircleX size="20" color="var(--neutrals-50)" />
    </TextButton>
  </UploadedFileContainer>
);

export const FileInput = props => {
  const [uploadedImage, setUploadedImage] = useState(null);
  useEffect(() => {
    if (!props.value) {
      setUploadedImage(null);
    } else if (typeof props.value === 'string') {
      setUploadedImage(props.value);
    } else {
      setUploadedImage(URL.createObjectURL(props.value));
    }
  }, [props.value]);

  const handleChange = e => {
    var file = e.target.files[0];

    if (!['image/jpeg', 'image/gif', 'image/png'].includes(file.type)) {
      alert.log('Only images are allowed.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      alert('File must be less than 5MB.');
      return;
    }

    setUploadedImage(URL.createObjectURL(file));
    props.onChange(file);
  };

  const clear = () => {
    setUploadedImage(null);
    props.onChange(null);
  };

  return (
    <div>
      <FormLabel>
        {props.mandatory ? `${props.label} *` : props.label}
      </FormLabel>
      <FormDescription>
        ({props.description})
      </FormDescription>
      <ConditionalRender predicate={props.value || uploadedImage}>
        <UploadedFile
          file={props.value || uploadedImage}
          src={uploadedImage}
          onRemoveHandler={clear}
        />
      </ConditionalRender>
      <ConditionalRender predicate={!props.value && !uploadedImage}>
        <InputArea>
          <CustomInput
            type="file"
            id="img"
            name="img"
            accept="image/*"
            onChange={handleChange}
          />
          <IconUpload size="25px" color="var(--neutrals-50)" />
          <InputAreaLabel>Browse gallery</InputAreaLabel>
        </InputArea>
      </ConditionalRender>
    </div>
  );
};
