import React, { useState } from 'react';
import styled from 'styled-components';
import { AnalyseListOfUnits } from '../../components/analyse-list-of-units';
import { MainButton, BackButton } from '../../components';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 0 20px;

  button {
    background: none;
    border: none;
  }
`;

const Step3 = props => {
  const [isDone, setIsDone] = useState(false);
  const [data, setData] = useState({});
  const onComplete = (data, isDone) => {
    console.log('onComplete')
    console.log(data);
    setData(data);
    setIsDone(isDone);
  };

  return (
    <>
      <BackButton to="/analyse-units/step-1">Step 1</BackButton>
      <HeaderContainer>
        <h2>My products</h2>
      </HeaderContainer>
      <Container>
        <p>Analyse units</p>
        <AnalyseListOfUnits units={props.units} onComplete={onComplete} />
        <MainButton onClickHandler={props.onDone(data)} disabled={!isDone}>
          Generate report
        </MainButton>
      </Container>
    </>
  );
};

export default Step3;
