import styled from 'styled-components';

export const FormLabel = styled.p`
  margin: 8px 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--neutrals-60);
`;

export const FormDescription = styled.p`
margin: 8px 2px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color: var(--neutrals-60);
`;