import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReminder, deleteReminder } from '../../redux/reminders';
import { TextButton, DateAndTimePicker } from '..';
import { formatDate } from '../../helpers';

import {
  MoreInfo,
  MoreInfoHeader,
  MoreInfoText,
  AnalyticsReminderContainer,
  AnalyticsDetailsContainer,
  EditButton,
} from './styles';
import { IconEdit } from '@tabler/icons';

const AnalyticsReminder = props => (
  <AnalyticsReminderContainer>
    <h4>Set an analytics reminder</h4>
    <DateAndTimePicker onChange={props.onAddReminder} value={props.value} />
  </AnalyticsReminderContainer>
);

export const AnalyticsDetails = ({ unit }) => {
  const dispatch = useDispatch();
  const reminder = useSelector(state => state.reminders.items[unit.id]);
  const latestReport = useSelector(state => {
    const reportIds = state.analyses.all[unit.id];
    if (!reportIds || reportIds.length === 0) {
      return null;
    }
    return state.analyses.byIds[reportIds[0]];
  });

  const onAddReminder = timeStamp => {
    if (reminder && !timeStamp) {
      dispatch(deleteReminder(unit.id));
    } else {
      dispatch(addReminder({ heatExchangerId: unit.id, timeStamp }));
    }
  };

  return (
    <AnalyticsDetailsContainer>
      <MoreInfo>
        <div>
          <div>
            <MoreInfoHeader>Status</MoreInfoHeader>
            <MoreInfoText>
              {latestReport
                ? `${latestReport.hotSideResult} / ${latestReport.coldSideResult}`
                : 'No report'}
            </MoreInfoText>
          </div>
          <div>
            <MoreInfoHeader>Next checkup</MoreInfoHeader>
            <MoreInfoText>
              {reminder ? formatDate(reminder.timeStamp) : 'Unknown'}
            </MoreInfoText>
          </div>
        </div>
      </MoreInfo>
      <AnalyticsReminder
        onAddReminder={onAddReminder}
        value={reminder && reminder.timeStamp}
      />
      <EditButton>
        <TextButton to={`/edit-unit/${unit.id}`}>
          <IconEdit color="var(--neutrals-70)" />
          Edit product
        </TextButton>
      </EditButton>
    </AnalyticsDetailsContainer>
  );
};
