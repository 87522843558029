import React from 'react';
import styled from 'styled-components';
import {
  MainButton,
  MinimalPresentationalUnit,
  BackButton,
} from './../../components';
import { IconStethoscope } from '@tabler/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedList = styled.ul`
  background: var(--neutrals-10);
  margin: 20px 0;
  width: 100%;

  li {
    cursor: pointer;
    div.minimal-presentational-unit {
      border-bottom: 1px solid var(--neutrals-30);
      cursor: pointer;
    }
  }
`;

const NotSelectedList = styled.ul`
  background-color: var(--white);
  margin: 20px 0;
  width: 100%;

  li {
    cursor: pointer;
    div.minimal-presentational-unit {
      border-bottom: 1px solid var(--neutrals-30);
      &:nth-of-type(2n) {
        background: var(--neutrals-10);
      }
    }
  }
`;

const SelectedContainer = styled.div`
  background-color: var(--blue-primary);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  h4 {
    color: var(--white);
  }
`;

const NotSelectedContainer = styled(SelectedContainer)`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  h4 {
    color: var(--blue-primary);
  }
`;

const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  button {
    flex-shrink: 1;
    margin: 10px 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;

  button {
    background: none;
    border: none;
  }
`;

const Step1 = ({ setUnits, units, allUnits, onNextClick }) => {
  const toggleUnit = unit => {
    const index = units.findIndex(u => u.id === unit.id);
    if (index > -1) {
      setUnits([
        ...units.slice(0, index),
        ...units.slice(index + 1, units.length),
      ]);
    } else {
      setUnits([...units, unit]);
    }
  };
  const selected = units.map(u => u.id);
  const notSelected = allUnits.filter(u => !selected.includes(u.id));

  return (
    <>
      <BackButton to="/home">Home</BackButton>
      <HeaderContainer>
        <h2>Analysis</h2>
      </HeaderContainer>
      <Container>
        <h3>Click on units you want to analyse</h3>
        <SelectedContainer>
          <IconStethoscope size="50" color={'var(--white)'} />
          <h4>Selected units</h4>
          <SelectedList>
            {units.map(unit => (
              <li key={`selectable-unit-${unit.id}`}>
                <MinimalPresentationalUnit
                  unit={unit}
                  onClickHandler={toggleUnit}
                  selected={units.find(u => u.id === unit.id)}
                />
              </li>
            ))}
          </SelectedList>
        </SelectedContainer>

        <NotSelectedContainer>
          <h4>Not selected units</h4>
          <NotSelectedList>
            {notSelected.map(unit => (
              <li key={`selectable-unit-${unit.id}`}>
                <MinimalPresentationalUnit
                  unit={unit}
                  onClickHandler={toggleUnit}
                />
              </li>
            ))}
          </NotSelectedList>
        </NotSelectedContainer>

        <ButtonContainer>
          <MainButton
            onClickHandler={onNextClick}
            disabled={units.length === 0}>
            Next step
          </MainButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default Step1;
