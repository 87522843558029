import React from 'react';
import { FileInput } from '../../../components';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
`;

const label = 'Unit avatar image';
const description = 'This is NOT the thermal image to use for analysis, but a "profile picture" for the unit.';

export const UploadImage = props => {
  const onChange = value => {
    const isValid = props.mandatory
      ? value && value.data !== null && value.name !== null
      : true;
    props.onChange(value, isValid);
  };

  return (
    <Container id={props.id}>
      <FileInput
        label={label}
        value={props.value}
        description={description}
        onChange={onChange}
        mandatory={props.mandatory}
      />
    </Container>
  );
};
