import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ConditionalRender } from './../../components';
import { IconRotateClockwise2 } from '@tabler/icons';
import { FaultReport } from './components';
import { TwoColumnGrid, UnitSummary } from '../../components/unit-summary';
import ReactToPrint from 'react-to-print';
import { Icon } from '@fluentui/react';
import { MainButton } from '../../components/buttons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    padding: 20px;
  }
`;

const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
`;

const PendingReportContainer = styled.div`
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  top: 30%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 0;
    padding: 0;
  }
  svg {
    -webkit-animation: spin 1.5s ease-in-out infinite;
    -moz-animation: spin 1.5s ease-in-out infinite;
    animation: spin 1.5s ease-in-out infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;


const delay = (t, v) => {
  return new Promise(resolve => setTimeout(resolve, t, v));
}

const PendingReport = props => (
  <BackDrop>
    <PendingReportContainer>
      <h2>Pending report</h2>
      <IconRotateClockwise2 size="50" color="var(--blue-primary)" />
    </PendingReportContainer>
  </BackDrop>
);

export const UnitReport = (props) => {
  const [showGeneralInformation, toggleGeneralInformation] = useState(false);
  const id = props.match.params.id;
  const report = useSelector(state => state.analyses.byIds[id]);
  const unit = useSelector(state => state.units.byIds[report.heatExchangerId]);
  const unitCustomer = useSelector(state => state.customers.items[unit.customerId]);

  const componentRef = useRef();

  return (
    <Container>
      <TwoColumnGrid>
        <h2>Report</h2>
      </TwoColumnGrid>
      <ConditionalRender predicate={report} fallback={'Report not found'}>
        <div ref={componentRef}>
          <div>
            <UnitSummary unit={unit} report={report} customer={unitCustomer} expand={showGeneralInformation} handleExpand={toggleGeneralInformation} />
          </div>
          <FaultReport report={report} />
          <ConditionalRender
            predicate={
              report.hotSideResult === 'Pending' ||
              report.coldSideResult === 'Pending'
            }>
            <PendingReport />
          </ConditionalRender>
        </div>

        <ReactToPrint
          trigger={() => { return <center><MainButton><Icon iconName='Print' style={{ padding: '0px 10px' }} /> Print as PDF</MainButton></center> }}
          // Waiting for a small delay between the print button click and the print window opening to show all content.
          onBeforeGetContent={async () => { toggleGeneralInformation(true); await delay(500) }}
          content={() => componentRef.current}
        />
      </ConditionalRender>
    </Container>
  );
};
