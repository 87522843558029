import React from 'react';
import { MinimalPresentationalUnit } from '..';
import {
  FlowRate,
  UploadImageHot,
  UploadImageCold,
  SteadyState,
  SetReminderAfterAnalysis,
  Attachments,
} from './form-components';
import { ConditionalRender } from '..';

export const AnalyseUnit = props => (
  <div>
    <MinimalPresentationalUnit
      unit={props.unit}
      onClickHandler={props.onClickHandler}
    />
    <ConditionalRender predicate={props.show}>
      <FlowRate
        value={props.data.flowRate}
        onChange={props.onChange}
        mandatory
      />
      <UploadImageHot
        value={props.data.hotSideImage}
        onChange={props.onChange}
        mandatory
      />
      <UploadImageCold
        value={props.data.coldSideImage}
        onChange={props.onChange}
        mandatory
      />
      <SteadyState
        value={props.data.steadyState}
        onChange={props.onChange}
        mandatory
      />
      <SetReminderAfterAnalysis
        value={props.data.reminder}
        onChange={props.onChange}
        unit={props.unit}
     />
     
      {/*
        TODO: Feature might come. Aligning on development path before it is implemented.
      <Attachments
        value={props.data.reminder}
        onChange={props.onChange}
        unit={props.unit} /> */}
    </ConditionalRender>
  </div>
);
