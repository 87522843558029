import React, { useState, useContext } from 'react';
import { loginRequest } from './authConfig';
import { pick, isObject } from './helpers';
import jwt_decode from 'jwt-decode';
import { APP_ROLE } from './constants';

const toJSON = resp => resp.json();

class API {
  constructor({ msalInstance }) {
    this.msalInstance = msalInstance;
    this.url = `${process.env.REACT_APP_API_URL}/api`;
    this.account = this.msalInstance.getAllAccounts()[0];
    this.token = null;
    this.roles = [];
  }

  renewToken = async () => {
    // console.log('API :: renewToken()');
    const silentRequest = {
      ...loginRequest,
      account: this.account,
    };

    let t = await this.msalInstance
    .acquireTokenSilent(silentRequest)
    .then(pick('accessToken'))
    .catch(error => {
      if (error.name === 'InteractionRequiredAuthError') {
        // fallback to interaction when silent call fails
        return this.msalInstance.acquireTokenRedirect(silentRequest);
      }
    });

    this.token = t;
    const decoded = jwt_decode(t);
    this.roles = decoded.roles
    if(decoded.roles === undefined || !decoded.roles.find(x => x === APP_ROLE)){
      return Promise.reject('User is not authorized');
      throw new Error('User is not authorized to use this app');
    }
  };

  doFetch = async (path, method, { headers, ...rest } = {}) => {
    await this.renewToken().catch(error => {
      console.error('caught error', error);
      throw error;
    });

    return fetch(`${this.url}${path}`, {
      method,
      headers: new Headers({
        Authorization: `Bearer ${this.token}`,
        ...(headers || {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
        }),
      }),
      ...rest,
    }).then(res => {
      
      if (res.ok) {
        return res;
      }
      else {
  
        let errorMessage = ''
        console.log('res', res)

        if(res.status !== 404){
          res.text().then(data => {
            console.log(data);
            errorMessage = '';
            if(data !== undefined && data !== null && data !== ''){
              console.log(data);
              try{
                const jsonData = JSON.parse(data);
            
    
                Object.keys(jsonData.errors).forEach(key => {
                  errorMessage += jsonData.errors[key] + '\r'
                });
              
              }
              catch {

              }
              
            }
            else{
              errorMessage = res.statusText;
            }
            

            Promise.reject(`An error occurred calling the api. ERROR: ${errorMessage}`);
            //throw new Error(`Could not get something from the api. ERROR: ${errorMessage}`)
          })
        }
      }
    });
  };

  /////// Identity

  signUp = async () => {
    // console.log('API :: signUp()');
    this.account = this.msalInstance.getAllAccounts()[0];
    return this.doFetch(`/identity/signup`, 'post')
      .then(() => {
        return true;
      })
      .catch(err => {
        console.log(err);
        Promise.reject('Unauthorized. Make sure you have the correct role set up for the User.');
        throw new Error('Unauthorized. Make sure you have the correct role set up for the User.');
      });
  };

  /////// Customers
  getCustomers = async () => {
    // console.log('API :: getCustomers()');
    return this.doFetch(`/customers`, 'get').then(toJSON);
  };

  getCustomer = async id => {
    // console.log('API :: getCustomer()');
    return this.doFetch(`/customers/${id}`, 'get').then(toJSON);
  };

  createCustomer = async body => {
    // console.log('API :: createCustomer()');
    return this.doFetch(`/customers`, 'post', {
      body: JSON.stringify(body),
    }).then(toJSON);
  };

  /////// Units
  getUnits = async () => {
    // console.log('API :: getUnits()');
    return this.doFetch(`/heatexchanger`, 'get').then(toJSON);
  };

  getUnit = async id => {
    // console.log('API :: getUnit()');
    return this.doFetch(`/heatexchanger/${id}`, 'get').then(toJSON);
  };

  createUnit = async unit => {
    const data = { ...unit };
    // console.log('API :: createUnit()');

    const body = new FormData();

    if (data.image) {
      body.append('image', data.image);
      delete data.image;
    }

    // Only handles objects with two levels of depth, if going three or more levels convert to recursive implementation
    for (const key in data) {
      if (isObject(data[key])) {
        for (const subKey in data[key]) {
          body.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        body.append(key, data[key]);
      }
    }

    console.log(data);

    return this.doFetch(`/heatexchanger`, 'post', {
      headers: {},
      body,
    }).then(toJSON);
  };

  editUnit = async (id, unit) => {
    const data = { ...unit };
    // console.log('API :: editUnit()');

    const body = new FormData();

    if (data.image) {
      body.append('image', data.image);
      delete data.image;
    }

    // Only handles objects with two levels of depth, if going three or more levels convert to recursive implementation
    for (const key in data) {
      if (isObject(data[key])) {
        for (const subKey in data[key]) {
          body.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        body.append(key, data[key]);
      }
    }

    return this.doFetch(`/heatexchanger/${id}`, 'put', {
      headers: {},
      body,
    }).then(toJSON);
  };

  deleteUnit = async id => {
    // console.log('API :: deleteUnit()');
    return this.doFetch(`/heatexchanger/${id}`, 'delete').then(() => id);
  };

  getUnitImage = async id => {
    // console.log('API :: getUnitImage()');
    if(id !== undefined){
      return this.doFetch(`/heatexchanger/image/${id}`, 'get', {
        headers: {},
      });
    }
    
  };

  /////// Reminders

  getReminders = async () => {
    // console.log('API :: getReminders()');
    return this.doFetch(`/reminders`, 'get').then(toJSON);
  };

  createReminder = async ({ heatExchangerId, timeStamp }) => {
    // console.log('API :: createReminder()');
    return this.doFetch(`/reminders/${heatExchangerId}`, 'post', {
      body: JSON.stringify({ timeStamp }),
    }).then(toJSON);
  };

  deleteReminder = async id => {
    // console.log('API :: deleteReminder()');
    return this.doFetch(`/reminders/${id}`, 'delete').then(() => id);
  };

  /////// Analysis

  getAnalysesResults = async () => {
    // console.log('API :: getAnalyses()');
    return this.doFetch(`/analyses`, 'get').then(toJSON);
  };

  getAnalysisResult = async id => {
    // console.log('API :: getAnalysis()');
    return this.doFetch(`/analyses/${id}`, 'get').then(toJSON);
  };

  getLatestAnalysisResult = async id => {
    // console.log('API :: getLatestAnalysis()');
    return this.doFetch(`/analyses/latest/${id}`, 'get').then(toJSON);
  };

  createAnalysis = async analysis => {
    // console.log('API :: createAnalysis()');

    const data = { ...analysis };
    const body = new FormData();
    let flowRate = data.flowRate.toString();
    data.flowRate = flowRate.toString();


    delete data.steadyState;
    delete data.reminder;

    console.log(data);

    if (data.hotSideImage !== undefined) {
      body.append('hotSideImage', data.hotSideImage);
      //delete data.hotSideImage;
    }

    if (data.coldSideImage !== undefined) {
      body.append('coldSideImage', data.coldSideImage);
      //delete data.coldSideImage;
    }

    // Only handles objects with two levels of depth, if going three or more levels convert to recursive implementation
    for (const key in data) {
      if (isObject(data[key])) {
        for (const subKey in data[key]) {
          body.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        body.append(key, data[key]);
      }
    }

    return this.doFetch(`/analyses/start`, 'post', {
      headers: {},
      body,
    }).then(toJSON);
  };
}

const APIContext = React.createContext(null);

const APIProvider = props => {
  const [api] = useState(props.api);

  return (
    <APIContext.Provider value={{ ...api }}>
      {props.children}
    </APIContext.Provider>
  );
};

const useAPI = () => useContext(APIContext);

export { API, APIProvider };
export default useAPI;
