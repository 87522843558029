import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 10px;
  button {
    flex-grow: 0;
    flex-shrink: 1;
    width: 50%;
    &:first-of-type {
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      margin-left: 5px;
    }
  }
`;

export const ExpansionIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  li {
    width: 100%;
  }
`;

export const Body = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  background-color: var(--neutrals-10);
  padding: 0 30px;
`;

export const ReminderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  text-align: right;
  ${props => props.invalid && `color: var(--neutrals-40);`}

  @media (min-width: 576px) {
    justify-content: flex-start;
    align-items: center;
    width: unset;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const ReportDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  @media (min-width: 576px) {
    width: unset;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Head = styled.div`
  border-bottom: 1px solid var(--neutrals-40);
  display: grid;

  h4 {
    cursor: pointer;

    svg {
      margin-left: 5px;
    }
  }
  .minimal-presentational-unit {
    flex-grow: 1;
  }
`;

export const ListHeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  &:last-of-type {
    display: flex;
  }

  &:first-of-type {
    text-align: left;
    justify-self: unset;
    @media (min-width: 480px) {
      margin-left: 80px;
    }
    justify-content: flex-start;
  }
  > h4 {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate3d(150%, -50%, 0);
    }
  }

  @media (min-width: 576px) {
    justify-content: flex-start;
  }
`;

export const GroupUnitsLabel = styled.li`
  margin-top: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  position: sticky;
  top: 38px;
  background-color: var(--white);
  z-index: 2;
  border-bottom: 1px solid var(--neutrals-40);
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
