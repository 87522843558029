import React from 'react';
import styled from 'styled-components';

const SIZES = { small: '14px', medium: '16px', large: '18px' };

const Styles = styled.p`
  font-style: normal;
  font-size: ${props => SIZES[props.size]};
  line-height: ${props => SIZES[props.size]};
  color: var(--neutrals-70);
`;

export const SerialNumber = props => (
  <Styles size={props.size || 'medium'}>{`s/n: ${props.children}`}</Styles>
);
