import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainButton } from '..';
import { handleError } from '../../redux/errors';
import { Dialog } from '../';

const prefix = `There was an issue with`;
const getDesription = ({ executor, issue, payload }, unitsByIds) => {
  const getUnit = () => unitsByIds[payload.heatExchangerId];
  switch (`${executor}_${issue}`) {
    case 'analyses_get':
      return `${prefix} fetching all analyses`;
    case 'analyses_create':
      return `${prefix} analysing heat exchanger "${getUnit().name}"`;

    case 'units_get':
      return `${prefix} fetching all heat exchangers`;
    case 'units_create':
      return `${prefix} creating heat exchanger "${payload.name}"`;
    case 'units_edit':
      return `${prefix} editing heat exchanger "${payload.name}"`;
    case 'units_delete':
      return `${prefix} deleting heat exchanger "${getUnit().name}"`;

    case 'reminders_get':
      return `${prefix} fetching all reminders`;
    case 'reminders_create':
      return `${prefix} creating the reminder for heat exchanger "${
        getUnit()?.name
      }"`;
    case 'reminders_delete':
      return `${prefix} deleting the reminder for heat exchanger "${
        getUnit()?.name
      }"`;

    case 'customers_get':
      return `${prefix} fetching all customers`;
    case 'customers_create':
      return `${prefix} creating the customer "${payload.name}"`;

    default:
      return 'error';
  }
};

export const ErrorMessages = props => {
  const dispatch = useDispatch();
  const errors = useSelector(state => state.errors);
  const unitsByIds = useSelector(state => state.units.byIds);

  const error = errors[0];
  if (!error) {
    return null;
  }

  return (
    <Dialog show={error}>
      <h3>Error</h3>
      <p>{getDesription(error, unitsByIds)}</p>
      <MainButton onClickHandler={() => dispatch(handleError(error.errorId))}>
        Ok
      </MainButton>
    </Dialog>
  );
};
