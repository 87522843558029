import styled from 'styled-components';

export const UnitOverviewContainer = styled.li`
  padding: 24px;
  background: var(--white);
  border: 1px solid var(--neutrals-40);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 20px 0;

  img {
    border: 1px solid var(--neutrals-30);
  }

  > button {
    width: auto;
    grid-column: 3 span;
  }

  .remove-unit-btn {
    grid-column: 1 span;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 576px) {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    button {
      grid-column: 2 span;
    }
  }
`;

export const ImageContainer = styled.div`
  grid-column: 1 span;
  @media (min-width: 576px) {
    grid-row: 2 span;
    grid-column: 3 span;
  }
`;

export const StatusBoxContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--blue-primary);
  margin: 10px 0;
`;

export const FlexContainer = styled.div`
  margin: 20px 0;
  display: flex;
`;

export const TopInfoBox = styled.div`
  grid-column: 2 span;

  @media (min-width: 576px) {
    grid-column: 5 span;
  }
`;

export const RecentCheckup = styled.div`
  > button {
    align-items: flex-start;
    text-align: left;
    padding: 0;
    text-decoration: underline;
  }
`;
