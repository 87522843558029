import { createSlice } from '@reduxjs/toolkit';

export const reportToErrorQueue = (dispatch, payload) => () => {
  dispatch(addError(payload));
  return Promise.reject();
};

export const analysesSlice = createSlice({
  name: 'errors',
  initialState: [],
  reducers: {
    addError: (state, action) => [
      ...state,
      { ...action.payload, errorId: new Date().getTime() },
    ],
    handleError: (state, { payload }) =>
      state.filter(({ errorId }) => errorId !== payload),
  },
});

export const { handleError, addError } = analysesSlice.actions;
export default analysesSlice.reducer;
