import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { differenceInDays, differenceInSeconds } from 'date-fns';
import { mapBy } from './../../helpers';

import { Container, FlexContainer, ScrollContainer } from './styles';
import Reminders from './Reminders';
import PassedReminders from './PassedReminders';
import NeedAnalysis from './NeedAnalysis';

import { IconReport } from '@tabler/icons';
import {
  getUnitsOfCurrentCustomer,
  reportsPerUnit,
} from '../../redux/selectors';

const getActiveReminders = (reminders, reportsByUnit, units) => {
  const unitsById = mapBy(units, 'id');
  return Object.entries(reminders)
    .filter(([unitId]) => unitsById[unitId])
    .sort(([_a, a], [_b, b]) => new Date(a.timeStamp) - new Date(b.timeStamp))
    .map(([unitId, reminder]) => {
      const daysUntil = differenceInDays(
        new Date(reminder.timeStamp),
        new Date()
      );

      return {
        reminder,
        ...unitsById[unitId],
        reports: [...(reportsByUnit[unitId] || [])],
        daysUntil,
        isValid:
          daysUntil === 0
            ? differenceInSeconds(new Date(reminder.timeStamp), new Date()) > 0
            : daysUntil > 0,
      };
    });
};

const getUnits = (units, reportsByUnit) =>
  units.map(u => ({
    ...u,
    reports: reportsByUnit[u.id],
    latestReport: reportsByUnit[u.id] && reportsByUnit[u.id][0],
  }));

export const UpcomingActions = props => {
  const [expanded, setExpanded] = useState(0);
  const reportsByUnit = useSelector(reportsPerUnit);
  const units = getUnits(useSelector(getUnitsOfCurrentCustomer), reportsByUnit);
  const reminders = getActiveReminders(
    useSelector(state => state.reminders.items),
    reportsByUnit,
    units
  );

  const toggleExpanded = key => setExpanded(prev => (key === prev ? 0 : key));

  return (
    <Container>
      <FlexContainer>
        <IconReport size="40" color="var(--white)" stroke="1" />
        <h3>Upcoming actions</h3>
      </FlexContainer>
      <ScrollContainer>
        <ul>
          <NeedAnalysis
            items={units}
            toggleExpanded={toggleExpanded}
            expanded={expanded}
          />
          <Reminders
            items={reminders.filter(i => i.isValid)}
            toggleExpanded={toggleExpanded}
            expanded={expanded}
          />
          <PassedReminders
            items={reminders}
            toggleExpanded={toggleExpanded}
            expanded={expanded}
          />
        </ul>
      </ScrollContainer>
    </Container>
  );
};
