import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reportToErrorQueue } from './errors';
import { formatISO, subMinutes } from 'date-fns';

const toLocalReminder = reminder => ({
  ...reminder,
  timeStamp: formatISO(
    subMinutes(
      new Date(new Date(reminder.timeStamp)),
      new Date().getTimezoneOffset()
    )
  ),
});

export const fetchReminders = createAsyncThunk(
  'reminders/fetchReminders',
  async (_, { extra: API, dispatch }) =>
    API.getReminders().catch(
      reportToErrorQueue(dispatch, {
        executor: 'reminders',
        issue: 'get',
        payload: null,
      })
    )
);

export const addReminder = createAsyncThunk(
  'reminders/addReminder',
  async ({ heatExchangerId, timeStamp }, { extra: API, dispatch }) =>
    API.createReminder({ heatExchangerId, timeStamp }).catch(
      reportToErrorQueue(dispatch, {
        executor: 'reminders',
        issue: 'create',
        payload: { heatExchangerId, timeStamp },
      })
    )
);

export const deleteReminder = createAsyncThunk(
  'reminders/deleteReminder',
  async (heatExchangerId, { extra: API, dispatch }) =>
    API.deleteReminder(heatExchangerId).catch(
      reportToErrorQueue(dispatch, {
        executor: 'reminders',
        issue: 'delete',
        payload: { heatExchangerId },
      })
    )
);

export const remindersSlice = createSlice({
  name: 'reminders',
  initialState: { items: {}, fetched: false },
  extraReducers: {
    [fetchReminders.fulfilled]: (state, { payload }) => ({
      ...state,
      fetched: true,
      items: payload.reminders.reduce(
        (acc, r) => ({
          ...acc,
          [r.heatExchangerId]: toLocalReminder(r),
        }),
        {}
      ),
    }),
    [addReminder.fulfilled]: (state, { payload }) => ({
      ...state,
      items: {
        ...state.items,
        [payload.reminder.heatExchangerId]: payload.reminder,
      },
    }),
    [deleteReminder.fulfilled]: (state, { payload }) => ({
      ...state,
      items: Object.keys(state.items).reduce(
        (acc, heatExchangerId) =>
          heatExchangerId === payload
            ? acc
            : { ...acc, [heatExchangerId]: state.items[heatExchangerId] },
        {}
      ),
    }),
  },
});

export default remindersSlice.reducer;
