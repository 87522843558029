import React from 'react';
import { Route } from 'react-router-dom';
import useAuth from './../../hooks/useAuth';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? <Component {...props} /> : null)}
    />
  );
};
