import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Home,
  UnitDetails,
  PrivateRoute,
  ConditionalRender,
  LoadingSpinner,
  ErrorMessages,
} from './components';
import {
  AddUnit,
  UnitList,
  AnalyseUnits,
  AnalyseSingleUnit,
  UnitReport,
} from './pages';
import { fetchCustomers } from './redux/customers';
import { fetchUnits } from './redux/units';
import { fetchReminders } from './redux/reminders';
import { fetchAnalyses } from './redux/analyses';
import { Switch } from 'react-router-dom';

const App = () => {
  const [errorData, setErrorData] = useState('');
  const dispatch = useDispatch();
  const signedIn = useSelector(({ user }) => user.signedIn);
  const signInRejected = useSelector(({ user }) => user.wasRejected);
  const hasUnits = useSelector(({ units }) => units.fetched);
  const hasCustomers = useSelector(({ customers }) => customers.fetched);
  const hasReminders = useSelector(({ reminders }) => reminders.fetched);
  const hasAnalyses = useSelector(({ analyses }) => analyses.fetched);
  const isPendingUnit = useSelector(({ units }) => units.currentState.pending);
  const errors = useSelector(state => state.errors);

  const isAllFetched = hasCustomers && hasUnits && hasReminders && hasAnalyses;

  const contentIsPending = isPendingUnit;

  useEffect(() => {
    if (signedIn) {
      dispatch(fetchCustomers());
      dispatch(fetchUnits());
      dispatch(fetchReminders());
      dispatch(fetchAnalyses());
    }
  }, [signedIn, signInRejected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Switch>
        <ConditionalRender
          predicate={isAllFetched && signInRejected === false}
        >
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute path="/" exact component={Home} />
          <PrivateRoute path="/add-unit/:sn?" component={AddUnit} />
          <PrivateRoute path="/edit-unit/:id?" component={AddUnit} />
          <PrivateRoute path="/list-units/:type?" component={UnitList} />
          <PrivateRoute path="/unit-details/:id" component={UnitDetails} />
          <PrivateRoute path="/analyse-units/:step?" component={AnalyseUnits} />
          <PrivateRoute path="/unit-report/:id?" component={UnitReport} />
          <AnalyseSingleUnit />
          <ErrorMessages />
          <ConditionalRender predicate={contentIsPending && signInRejected === false}>
            <LoadingSpinner size="large" fullPage backDrop />
          </ConditionalRender>
        </ConditionalRender>
      </Switch>
    </>
  );
};

export default App;
