import React from 'react';
import { useDispatch } from 'react-redux';
import { MainButton } from './';
import { startAnalysis } from '../../redux/analyses';

export const RunAnalyticsButton = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <MainButton onClickHandler={() => dispatch(startAnalysis(id))}>
      Analyse
    </MainButton>
  );
};
