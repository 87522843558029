import React, { useState } from 'react';
import { TextButton, MainButton, DateAndTimePicker, Dialog } from '../';
import { useDispatch } from 'react-redux';
import { addReminder } from './../../redux/reminders';

export const ReminderPopup = ({
  units,
  label,
  onDone,
  textButton,
  disabled,
  disableBackdropClick,
}) => {
  const [showPrompt, toggleDialog] = useState(false);
  const [timeStamp, setTimeStamp] = useState('');
  const dispatch = useDispatch();

  const onClose = () => {
    units.forEach(unit =>
      dispatch(addReminder({ heatExchangerId: unit.id, timeStamp }))
    );
    onDone && onDone();
    toggleDialog(false);
  };

  const Button = textButton ? TextButton : MainButton;

  return (
    <>
      <Dialog
        show={showPrompt}
        onClose={onClose}
        disableBackdropClick={disableBackdropClick}>
        <h4>Reminder</h4>
        <p>Pick a time and date as a reminder</p>
        <DateAndTimePicker onChange={setTimeStamp} value={timeStamp} />
        <MainButton onClickHandler={onClose}>Done</MainButton>
      </Dialog>
      <Button onClickHandler={() => toggleDialog(true)} disabled={disabled}>
        {label}
      </Button>
    </>
  );
};
