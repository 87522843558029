import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDown } from '../';
import styled from 'styled-components';
import { setCurrentCustomer } from '../../redux/customers';

const Container = styled.div`
  grid-column: 1 span;
  background-color: var(--neutrals-20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  input {
    max-width: 400px;
  }
  p {
    color: var(--neutrals-60);
  }
  @media (min-width: 576px) {
    grid-column: 2 span;
  }

  @media (min-width: 768px) {
    grid-column: 4 span;
  }

  @media (min-width: 1024px) {
    grid-column: 6 span;
  }
`;

export const SingleOutCustomer = props => {
  const dispatch = useDispatch();
  const currentCustomer = useSelector(state => state.customers.currentCustomer);
  const options = Object.entries(
    useSelector(state => state.customers.items)
  ).map(([value, label]) => ({ label, value }));

  return (
    <Container>
      <DropDown
        label={'Set current customer for the application'}
        value={currentCustomer || ''}
        options={options}
        onChange={id => dispatch(setCurrentCustomer(id === 'none' ? null : id))}
        disableFirst={false}
      />
    </Container>
  );
};
