import React from 'react';
import { ReminderPopup } from '..';
import { StatusBoxContainer, RecentCheckup } from './styles';
import { formatDate } from '../../helpers';
import { format, parseISO } from 'date-fns';

const StatusBox = ({ report, reminder, unit }) => (
  <StatusBoxContainer>
    {report ? (
      <RecentCheckup>
        {`Checkup done ${formatDate(report.startTimestamp)}`}
        {reminder ? (
          <p>{`Next reminder: ${format(
            parseISO(reminder.timeStamp),
            'yyyy-MM-dd HH:mm'
          )}`}</p>
        ) : (
          <ReminderPopup
            label={'Set a next reminder'}
            units={[unit]}
            textButton
          />
        )}
      </RecentCheckup>
    ) : (
      'Start with troubleshooting for a greater insight into maintenance.'
    )}
  </StatusBoxContainer>
);
export default StatusBox;
