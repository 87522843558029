import React from 'react';
import styled from 'styled-components';
import { MinimalPresentationalUnit } from '../../components';
import { List, Body, Head } from './styles';
import { BodyButtons, Expansion, UnitsGroupHeader } from './columns';
import ListHeader from './ListHeader';

const Container = styled.div`
  margin-top: 24px;
  ul li > div {
    grid-template-columns: 1fr 35px;
  }
`;

const listHeaderOptions = [
  {
    label: 'Heat exchanger',
    value: 'name',
  },
];

const BasicList = ({
  units,
  expanded,
  toggleExpanded,
  setSortAttr,
  sortAttr,
  groupUnitsBy,
}) => (
  <Container>
    <ListHeader
      setSortAttr={setSortAttr}
      sortAttr={sortAttr}
      options={listHeaderOptions}
    />
    <List>
      {units.map((unit, index) => (
        <React.Fragment key={unit.id}>
          <UnitsGroupHeader
            groupUnitsBy={groupUnitsBy}
            index={index}
            unit={unit}
            units={units}
          />
          <li>
            <Head onClick={() => toggleExpanded(index)}>
              <MinimalPresentationalUnit unit={unit} />
              <Expansion expanded={expanded} index={index} />
            </Head>
            <Body show={expanded === index}>
              <BodyButtons id={unit.id} />
            </Body>
          </li>
        </React.Fragment>
      ))}
    </List>
  </Container>
);

export default BasicList;
