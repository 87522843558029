import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  IconMenu2,
  IconX,
  IconPlus,
  IconList,
  IconStethoscope,
  IconHome,
} from '@tabler/icons';
import { TextButton } from './../';
import { useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { AzureSignOutButton } from '../../components';
import { ConditionalRender } from '../conditional-render';

const Head = styled.div``;
const Body = styled.div`
  transition: transform 0.2s ease-in-out;
  background: var(--blue-primary);
  border-right: 1px solid var(--neutrals-30);
  width: 80%;
  max-width: 300px;
  transform: translateX(${props => (props.show ? 0 : '-110%')});
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 5px 0px 10px 0px rgb(0, 0, 0, 0.2);
  > div {
    position: relative;
  }
  @media (min-width: 576px) {
    width: 300px;
  }
  z-index: 99;
`;

const Backdrop = styled.div`
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 98;
  display: ${props => (props.show ? 'block' : 'none')};
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
  cursor: pointer;
`;

const Container = styled.div`
  z-index: 2;
`;

const ButtonList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  li {
    background: var(--neutrals-10);
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--neutrals-30);
    justify-content: center;
    button {
      color: var(--blue-primary);
      margin: 5px;
      width: 100%;
      display: flex;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      align-items: center;
      a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        svg {
          margin: 5px;
          width: 50px;
          margin-right: 20px;
        }
      }
    }
  }
`;

const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  h2 {
    color: var(--white);
    padding-left: 30px;
    padding-top: 100px;
  }
`;

const CloseButton = props => (
  <CloseButtonContainer>
    <IconX onClick={props.onClickHandler} color="var(--white)" />
  </CloseButtonContainer>
);

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  color: var(--neutrals-30);
  text-align: center;
  flex-grow: 0;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 30px;
  margin-top: 20px;
`;

export const MenuFooter = ({ isAuthenticated }) => (
  <>
    <ConditionalRender predicate={isAuthenticated}>
      <AzureSignOutButton />
    </ConditionalRender>
    <FooterContainer>© Alfa Laval</FooterContainer>
  </>
);

const HamburgerMenu = props => {
  const [show, toggleShow] = useState(false);
  const bodyRef = useRef();
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  const handleClick = e =>
    !bodyRef.current.contains(e.target) && toggleShow(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    const unlisten = history.listen(() => {
      toggleShow(false);
    });
    return () => {
      document.removeEventListener('mousedown', handleClick);
      unlisten();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Backdrop show={show} />
      <Head>
        <TextButton onClickHandler={() => toggleShow(prev => !prev)}>
          <IconMenu2 size="24" />
        </TextButton>
      </Head>
      <Body show={show} ref={bodyRef}>
        <BodyHeader>
          <h2>Menu</h2>
          <CloseButton onClickHandler={() => toggleShow(false)} />
        </BodyHeader>
        <ButtonList>
          <li>
            <TextButton to="/home">
              <IconHome size="25" stroke="1" color={'var(--blue-primary)'} />
              <p>Home</p>
            </TextButton>
          </li>
          <li>
            <TextButton to="/add-unit">
              <IconPlus size="25" stroke="1" color={'var(--blue-primary)'} />
              <p>Add Unit</p>
            </TextButton>
          </li>
          <li>
            <TextButton to="/list-units">
              <IconList size="25" stroke="1" color={'var(--blue-primary)'} />
              <p>Show Units</p>
            </TextButton>
          </li>
          <li>
            <TextButton to="/analyse-units/step-1">
              <IconStethoscope
                size="25"
                stroke="1"
                color={'var(--blue-primary)'}
              />
              <p>Analyse Units</p>
            </TextButton>
          </li>
        </ButtonList>
        <MenuFooter isAuthenticated={isAuthenticated} />
      </Body>
    </Container>
  );
};

export default HamburgerMenu;
