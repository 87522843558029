import { format, formatDistance, differenceInDays } from 'date-fns';

export const formatDate = (timestamp, short) => {
  const reportDate = new Date(timestamp);
  const lessThan7DaysAgo = differenceInDays(new Date(), reportDate) < 7;
  const relativeDate = formatDistance(reportDate, new Date(), {
    addSuffix: true,
  }).replace('about ', '');
  const absoluteDate = format(reportDate, 'dd MMM yyyy');
  if (lessThan7DaysAgo) {
    return relativeDate;
  } else {
    return absoluteDate;
  }
};
