export const reportsPerUnit = state =>
  Object.entries(state.analyses.all)
    .filter(([unitId]) => state.units.ids.includes(unitId))
    .reduce(
      (acc, [unitId, reportIds]) => ({
        ...acc,
        [unitId]: [
          ...(acc[unitId] || []),
          ...reportIds.map(id => state.analyses.byIds[id]),
        ].sort(
          (a, b) => new Date(b.startTimestamp) - new Date(a.startTimestamp)
        ),
      }),
      {}
    );

export const getUnitsOfCurrentCustomer = state =>
  state.customers.currentCustomer
    ? state.units.items
        .filter(u => u.customerId === state.customers.currentCustomer)
        .map(x => x)
    : state.units.items.map(x => x);
