import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import DesktopMenu from './DesktopMenu';
import { IconShoppingCart } from '@tabler/icons';
import { Protected } from '../../hooks/useAuth';
import Logo from './Logo.js';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 75px;
  background-color: var(--white);
  color: var(--blue-primary);
  box-sizing: border-box;
  img {
    height: 40px;
  }

  @media (min-width: 768px) {
    height: auto;
    img {
      margin: 20px 0 10px 0;
    }
  }
`;

const SmallDevices = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const LargeDevices = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const TopHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    justify-content: center;
    padding: 40px;
  }
`;

const Cart = () => (
  <div>
    <IconShoppingCart size="24" />
  </div>
);

export const Header = () => (
  <Protected>
    <HeaderContainer>
      <TopHeader>
        <SmallDevices>
          <HamburgerMenu />
        </SmallDevices>
        <Link to={'/home'}>
          <Logo />
        </Link>
        <SmallDevices>
          <Cart />
        </SmallDevices>
      </TopHeader>
      <LargeDevices>
        <DesktopMenu />
      </LargeDevices>
    </HeaderContainer>
  </Protected>
);
