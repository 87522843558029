import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  color: var(--earth-secondary);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
`;

export const ErrorType = props => <Styles>{props.children}</Styles>;
