import React from 'react';
import styled from 'styled-components';
import { UnitImage } from '../';
import { UnitIdentity } from '../unit-identity';

const Container = styled.div`
  user-select: none;
  background-color: var(--white);
  padding: 5px 10px;
`;

const InfoBox = styled.div`
  position: relative;
  padding-top: 8px;
  height: 100%;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-column-gap: 10px;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--neutrals-60);
  }
  @media (max-width: 479px) {
    grid-template-columns: 40px 1fr;
  }
`;

const UnitImageContainer = styled.div`
  grid-row: 3 span;
  padding-top: 8px;
`;

export const MinimalPresentationalUnit = ({
  unit,
  onClickHandler,
  lineBreaks,
}) => (
  <Container
    onClick={() => onClickHandler && onClickHandler(unit)}
    className="minimal-presentational-unit">
    <InfoBox>
      <UnitImageContainer>
        <UnitImage unit={unit} />
      </UnitImageContainer>
      <UnitIdentity unit={unit} lineBreaks={lineBreaks} />
      <p className="serialnumber">{`s/n: ${unit.serialNumber}`}</p>
    </InfoBox>
  </Container>
);
