import RegularImage from '../../assets/configuration_port_1.png';
import CondenserImage from '../../assets/configuration_port_2.png';
import EvaporatorImage from '../../assets/configuration_port_3.png';
import styled from 'styled-components';
import { FontIcon, getTheme } from '@fluentui/react';

const TableHeader = styled.th`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: var(--neutrals-70);
    display: flex;
    align-items: center;
    padding: 5px;
`;

const TableValue = styled.td`
    font-style: normal;
    font-size: 14px;
    color: var(--neutrals-70);
    margin: 0px;
    padding: 0px;
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
  width: 100%;
  margin: -10px;
  padding: 0px;
  transition: 175ms;
  
  :hover{
    cursor: pointer;
    background-color: var(--neutrals-20);
    transition: 175ms;
  }
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  width: 100%;
`;


export const UnitSummary = ({ unit, report, customer, expand, handleExpand }) => {

    let theme = getTheme();
    const portConfigurationImages = [
        { value: 'regular', img: RegularImage },
        { value: 'condenser', img: CondenserImage },
        { value: 'evaporator', img: EvaporatorImage }
    ];

    let a = [{ day: 'numeric' }, { month: 'long' }, { year: 'numeric' }];
    const reportDate = new Date(report.startTimestamp);
    const formattedDate = `${reportDate.toDateString(a)} ${reportDate.toLocaleTimeString()}`;

    const tableComponent = (unit, report, customer) => {
        return (
            <table style={{ width: '100%', marginTop: 10 }} >
                <tbody>
                    <tr>
                        <TableHeader>
                            Unit:
                        </TableHeader>
                        <TableValue>
                            {unit.name}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Customer:
                        </TableHeader>
                        <TableValue>
                            {customer}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Location:
                        </TableHeader>
                        <TableValue>
                            {unit.location}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Request date:
                        </TableHeader>
                        <TableValue>{formattedDate}</TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Serial number:
                        </TableHeader>
                        <TableValue>{unit.serialNumber}</TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Use case:
                        </TableHeader>
                        <TableValue>
                            {unit.isHeating ? 'Heating' : 'Cooling'}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Competitor unit:
                        </TableHeader>
                        <TableValue>
                            {unit.brand === 'alfa laval' ? 'No' : 'Yes'}
                        </TableValue>
                    </tr>
                </tbody>
                <tbody>
                    <h4>Technical</h4>
                    <tr>
                        <TableHeader>
                            Estimated flow rate:
                        </TableHeader>
                        <TableValue>
                            {`${(report.flowRate * 100)} %`}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Plate type configuration:
                        </TableHeader>
                        <TableValue>
                            {unit.isMixedTableHeadereta === false ? 'Mixed' : 'Unmixed'}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Plate pack size:
                        </TableHeader>
                        <TableValue>
                            {unit.sizeOfPlatePlack}
                        </TableValue>
                    </tr>
                    <tr>
                        <TableHeader>
                            Port configuration:
                        </TableHeader>
                        <TableValue>
                            <img src={portConfigurationImages.find(x => x.value === unit.portConfiguration.portConfigurationType).img}
                                alt='port configuration type'
                                style={{ height: 100 }} />
                        </TableValue>
                    </tr>
                    {
                        unit.portConfiguration.portConfigurationType === 'regular' &&
                        <tr>
                            <tr>
                                <TableHeader>
                                    S1:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.s1}
                                </TableValue>
                                <TableHeader>
                                    T1:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.t1}
                                </TableValue>
                            </tr>
                            <tr>
                                <TableHeader>
                                    S2:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.s2}
                                </TableValue>
                                <TableHeader>
                                    T2:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.t2}
                                </TableValue>
                            </tr>
                            <tr>
                                <TableHeader>
                                    S3:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.s3}
                                </TableValue>
                                <TableHeader>
                                    T3:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.t3}
                                </TableValue>
                            </tr>
                            <tr>
                                <TableHeader>
                                    S4:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.s4}
                                </TableValue>
                                <TableHeader>
                                    T4:
                                </TableHeader>
                                <TableValue>
                                    {unit.portConfiguration.t4}
                                </TableValue>
                            </tr>
                        </tr>
                    }

                </tbody>
            </table>
        )
    }

    return (
        <div style={{ background: theme.palette.neutralLight, margin: 10, padding: 10 }}>
            <TwoColumns onClick={() => handleExpand(!expand)} style={{ padding: 10 }}>
                <h4>General information</h4>
                <div>
                    <FontIcon
                        iconName={expand ? 'ChevronUp' : 'ChevronDown'}
                        style={{ cursor: 'pointer', padding: 8, float: 'right' }} />
                </div>


            </TwoColumns>
            {
                expand &&
                tableComponent(unit, report, customer)
            }
        </div>
    )
}