import React, { useEffect, useState } from 'react';
import StatusList from './StatusList';
import BasicList from './BasicList';
import RemindersList from './RemindersList';
import OverviewList from './OverviewList';
import { Switch, useHistory } from 'react-router';
import {
  BackButton,
  PrivateRoute,
  MainButton,
  ConditionalRender,
} from '../../components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  getUnitsOfCurrentCustomer,
  reportsPerUnit,
} from '../../redux/selectors';
import {
  getLatestReportDate,
  getLatestReportResult,
  getSortedUnits,
  sortByAttr,
} from './helpers';
import Filters from './Filters';

const ButtonContainer = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  justify-content: center;
  z-index: 5;

  button {
    padding: 10px;
    margin: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  h2 {
    text-align: center;
  }

  user-select: none;

  @media (min-width: 768px) {
    width: 768px;
  }
`;

const NoUnits = styled.div`
  padding-bottom: 20px;
  text-align: center;
`;

export const UnitList = props => {
  const history = useHistory();
  const reminders = useSelector(state => state.reminders.items);
  const reportsByUnit = useSelector(reportsPerUnit);
  const unitsOfCurrentCustomer = useSelector(getUnitsOfCurrentCustomer);
  const customers = useSelector(state => state.customers.items);

  const unsortedUnits = unitsOfCurrentCustomer.map(u => ({
    ...u,
    customer: customers[u.customerId],
    reminder: reminders[u.id] && new Date(reminders[u.id].timeStamp),

    reports: [...(reportsByUnit[u.id] || [])],
    latestReportResult: getLatestReportResult(reportsByUnit, u),
    latestReportDate: new Date(getLatestReportDate(reportsByUnit, u)),
  }));
  const [units, setUnits] = useState(unsortedUnits);
  const [sortAttr, setSortAttr] = useState('');
  const [expanded, setExpanded] = useState(-1);
  const [groupUnitsBy, setGroupUnitsBy] = useState(null);

  useEffect(
    () => {
      let sortedUnits =
        sortAttr !== ''
          ? getSortedUnits(unsortedUnits, sortAttr)
          : unsortedUnits;

      if (groupUnitsBy) {
        sortedUnits.sort(sortByAttr(groupUnitsBy));
      }
      setUnits(sortedUnits);
    },
    [sortAttr, groupUnitsBy] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => setUnits(unsortedUnits), [unitsOfCurrentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleExpanded = index =>
    setExpanded(prev => (prev === index ? -1 : index));

  const onListTypeClick = path => history.push(path);

  return (
    <>
      <BackButton to="/home">Home</BackButton>
      <Container>
        <h2>Units list</h2>
        <ConditionalRender
          predicate={units.length > 0}
          fallback={
            <NoUnits>You have no units added, start by adding a unit</NoUnits>
          }>
          <Filters
            groupUnitsBy={groupUnitsBy}
            setGroupUnitsBy={setGroupUnitsBy}
            onListTypeClick={onListTypeClick}
          />

          <Switch>
            <PrivateRoute path="/list-units" exact>
              <BasicList
                units={units}
                setSortAttr={setSortAttr}
                sortAttr={sortAttr}
                toggleExpanded={toggleExpanded}
                groupUnitsBy={groupUnitsBy}
                expanded={expanded}
              />
            </PrivateRoute>

            <PrivateRoute path="/list-units/overview">
              <OverviewList
                units={units}
                setSortAttr={setSortAttr}
                sortAttr={sortAttr}
                toggleExpanded={toggleExpanded}
                groupUnitsBy={groupUnitsBy}
                expanded={expanded}
              />
            </PrivateRoute>

            <PrivateRoute path="/list-units/status">
              <StatusList
                units={units}
                setSortAttr={setSortAttr}
                sortAttr={sortAttr}
                toggleExpanded={toggleExpanded}
                groupUnitsBy={groupUnitsBy}
                expanded={expanded}
              />
            </PrivateRoute>

            <PrivateRoute path="/list-units/reminders">
              <RemindersList
                units={units}
                setSortAttr={setSortAttr}
                sortAttr={sortAttr}
                toggleExpanded={toggleExpanded}
                groupUnitsBy={groupUnitsBy}
                expanded={expanded}
              />
            </PrivateRoute>
          </Switch>
        </ConditionalRender>

        <ButtonContainer>
          <MainButton to={'/add-unit'}>Add new unit</MainButton>
        </ButtonContainer>
      </Container>
    </>
  );
};
