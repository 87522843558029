import React, { useEffect } from 'react';
import { FormLabel } from '../..';
import styled from 'styled-components';
import { IconUpload, IconCircleX } from '@tabler/icons';

const Container = styled.div`
  margin: 20px 0;
`;
const InputAreaLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--neutrals-50);
  margin: 5px 0;
`;

const CustomInput = styled.input`
  opacity: 1;
  position: absolute;
  hidden: true;
  height: 40px;
  width: 100%;
`;

const label = 'Upload a cold side image';
const description = 'Click here to upload an image of the unit.';

export const Attachments = props => {
    const handleChange = e => {
        var file = e.target.files;
        console.log('files', file);

        if (file.size > 5 * 1024 * 1024) {
            alert('File must be less than 5MB.');
            return;
        }

        props.onChange(file);
    };


    useEffect(() => props.onChange('attachments', null, null), []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container id="upload-attachments">
            <FormLabel>
                Add additional attachments for analysis request. (optional)
            </FormLabel>
            <div style={{ padding: 20, background: 'blue' }}>
                <CustomInput type='file' multiple onChange={handleChange} accept='image/*' />
                <IconUpload size="25px" color="var(--neutrals-50)" />
                <InputAreaLabel>Attach additional images for analysis request</InputAreaLabel>
            </div>
        </Container>
    );
};
