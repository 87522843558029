import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteReminder, addReminder } from './reminders';
import { reportToErrorQueue } from './errors';
import { formatISO, subMinutes } from 'date-fns';

const toLocalReminder = timestamp =>
  formatISO(
    subMinutes(new Date(new Date(timestamp)), new Date().getTimezoneOffset())
  );

export const createAnalysis = createAsyncThunk(
  'analyses/createAnalysis',
  async (data, { extra: API, dispatch }) =>
    API.createAnalysis(data)
      .then(result => {
        if (data.reminder) {
          dispatch(
            addReminder({
              heatExchangerId: result.analysis.heatExchangerId,
              timeStamp: data.reminder,
            })
          );
        } else {
          dispatch(deleteReminder(result.analysis.heatExchangerId));
        }
        return result;
      })
      .catch(
        reportToErrorQueue(dispatch, {
          executor: 'analyses',
          issue: 'create',
          payload: data,
        })
      )
);

export const fetchAnalyses = createAsyncThunk(
  'analyses/getAnalysesResults',
  async (_, { extra: API, dispatch }) =>
    API.getAnalysesResults().catch(
      reportToErrorQueue(dispatch, {
        executor: 'analyses',
        issue: 'get',
        payload: null,
      })
    )
);

const byDate = attr => (a, b) => new Date(b[attr]) - new Date(a[attr]);
const accIdPerUnitId = (acc, a) => ({
  ...acc,
  [a.heatExchangerId]: [...(acc[a.heatExchangerId] || []), a.id],
});

export const analysesSlice = createSlice({
  name: 'analyses',
  initialState: {
    all: {},
    show: false,
    id: null,
    fetched: false,
    errors: [],
  },
  reducers: {
    startAnalysis: (state, { payload }) => ({
      ...state,
      show: true,
      id: payload,
    }),
    doneAnalysis: state => ({ ...state, show: false, id: null }),
    cancelAnalysis: state => ({ ...state, show: false, id: null }),
    handleAnalysisError: (state, { payload }) => ({
      ...state,
      errors: state.errors.filter(
        e =>
          !(
            e.heatExchangerId === payload.heatExchangerId &&
            e.issue === payload.issue
          )
      ),
    }),
  },
  extraReducers: {
    [fetchAnalyses.fulfilled]: (state, { payload }) => {
      payload.analyses.sort(byDate('startTimestamp'));
      return {
        ...state,
        fetched: true,
        byIds: payload.analyses.reduce((acc, a) => ({ ...acc, [a.id]: a }), {}),
        all: payload.analyses.reduce(accIdPerUnitId, {}),
      };
    },
    [createAnalysis.fulfilled]: (state, { payload }) => ({
      ...state,
      byIds: {
        ...state.byIds,
        [payload.analysis.id]: {
          ...payload.analysis,
          startTimestamp: toLocalReminder(payload.analysis.startTimestamp),
          finishTimestamp: toLocalReminder(payload.analysis.finishTimestamp),
        },
      },
      all: {
        ...state.all,
        [payload.analysis.heatExchangerId]: [
          ...(state.all[payload.analysis.heatExchangerId] || []),
          payload.analysis.id,
        ],
      },
    }),
  },
});

export const {
  startAnalysis,
  doneAnalysis,
  cancelAnalysis,
  handleAnalysisError,
} = analysesSlice.actions;
export default analysesSlice.reducer;
