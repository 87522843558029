import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ConditionalRender, MainButton } from '..';
import { AnalyseUnit } from '../analyse-unit/AnalyseUnit';

const ListItem = styled.li`
  border: 1px solid var(--neutrals-30);
  padding: 14px;
  margin: 10px 0;
  button {
    margin-top: 10px;
  }

  div.minimal-presentational-unit {
    padding: 0;
  }
`;

const getInitialState = units =>
  new Array(units.length).fill().map((_, index) => ({
    reminder: null,
    startTimestamp: new Date().toISOString(),
    heatExchangerId: units[index].id,
    unitName: units[index].name,
  }));

export const AnalyseListOfUnits = ({ units, onComplete }) => {
  const [data, setData] = useState(getInitialState(units));
  const [isValid, setIsValidInfo] = useState(getInitialState(units));

  const [expanded, setExpanded] = useState(0);

  const onChange = index => (key, value, valid) => {
    console.log(index, key, value);
    if (key === 'hotSideImage' && value !== null && data[index].coldSideImage !== null && data[index].coldSideImage !== undefined) {
      const coldReader = new FileReader();

      coldReader.readAsDataURL(value);
      coldReader.onloadend = () => {
        const hotReader = new FileReader();


        hotReader.readAsDataURL(data[index].coldSideImage);
        hotReader.onloadend = () => {
          if (hotReader.result === coldReader.result) {
            document.getElementById('upload-image-hot-error').hidden = false;
          }
          else {
            document.getElementById('upload-image-hot-error').hidden = true;
            document.getElementById('upload-image-cold-error').hidden = true;
          }
        }
      }

    }
    if (key === 'coldSideImage' && value !== null && data[index].hotSideImage !== null && data[index].hotSideImage !== undefined) {
      const coldReader = new FileReader();

      coldReader.readAsDataURL(value);
      coldReader.onloadend = (v) => {
        const hotReader = new FileReader();
        hotReader.readAsDataURL(data[index].hotSideImage);
        hotReader.onloadend = () => {
          if (hotReader.result === coldReader.result) {
            document.getElementById('upload-image-cold-error').hidden = false;
          }
          else {
            document.getElementById('upload-image-hot-error').hidden = true;
            document.getElementById('upload-image-cold-error').hidden = true;
          }
        }
      }
    }
    setData(prev =>
      prev.map((x, i) => (i === index ? { ...x, [key]: value } : x))
    );
    setIsValidInfo(prev =>
      prev.map((x, i) => (i === index ? { ...x, [key]: valid } : x))
    );
  };

  useEffect(
    () => onComplete(data, isValid.map(Object.values).flat().every(Boolean)),
    [JSON.stringify(data)] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isValidUnit = index =>
    Object.values(isValid[index]).length === 0
      ? false
      : Object.values(isValid[index]).every(Boolean);

  const onClickHandler = index => () => setExpanded(index);

  return (
    <div style={{ width: '100%' }}>
      <h2>Analysis</h2>
      <div>
        <p>
          In order to secure maximum accuracy of your thermal images, it is
          mandatory that your unit is at a steady state and the plate pack has
          been cleaned externally.
        </p>
        <ul>
          {units.map((unit, index) => (
            <ListItem key={`unit-${index}`} selected={index === expanded}>
              <AnalyseUnit
                unit={unit}
                onChange={onChange(index)}
                onClickHandler={onClickHandler(index)}
                data={data[index]}
                show={expanded === index}
              />
              <ConditionalRender
                predicate={index === expanded && index < units.length - 1}>
                <MainButton
                  onClickHandler={() => setExpanded(prev => prev + 1)}
                  disabled={!isValidUnit(index)}>
                  Next unit
                </MainButton>
              </ConditionalRender>
            </ListItem>
          ))}
        </ul>
      </div>
    </div>
  );
};
