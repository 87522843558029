export const sortByAttr = attr => (a, b) => {
  let valA = a[attr];
  let valB = b[attr];

  if (!valA) {
    return 1;
  }

  if (!valB) {
    return -1;
  }

  if (typeof valA === 'string' || typeof valB === 'string') {
    valA = valA.toLowerCase();
    valB = valB.toLowerCase();
  }

  return valA < valB ? -1 : valA > valB ? 1 : 0;
};

export const getFromLatestReport =
  attr =>
  (r, { id }) =>
    r[id] ? r[id][0][attr] : null;

export const getLatestReportResult = (r, u) => {
  const hotSide = getFromLatestReport('hotSideResult')(r, u);
  const coldSide = getFromLatestReport('coldSideResult')(r, u);

  return hotSide && coldSide ? `${hotSide} / ${coldSide}` : 'No Report';
};

export const getLatestReportDate = getFromLatestReport('startTimestamp');

export const unitHasReport = (reportsByUnit, unit) =>
  reportsByUnit[unit.id] && reportsByUnit[unit.id][0];

export const getSortedUnits = (arr, sortAttr) =>
  /asc$/.test(sortAttr)
    ? [...arr].sort(sortByAttr(sortAttr.match(/^(\w+)/)[0]))
    : [...arr].sort(sortByAttr(sortAttr.match(/^(\w+)/)[0])).reverse();
