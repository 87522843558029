import AddUnit from './AddUnit';
import { connect } from 'react-redux';
import { startAnalysis } from '../../redux/analyses';
import {
  addUnit,
  editUnit,
  getUnitImage,
  resetCurrentState,
} from '../../redux/units';
import { getUnitsOfCurrentCustomer } from '../../redux/selectors';

const isValidInfo = (valids, unitInfo) => {
  if (!valids.includes('name')) return { isValid: false, issue: 'name' };
  if (!valids.includes('brand')) return { isValid: false, issue: 'brand' };
  if (!valids.includes('customerId'))
    return { isValid: false, issue: 'customerId' };
  if (!valids.includes('portConfigurationType'))
    return { isValid: false, issue: 'portConfigurationType' };
  if (!valids.includes('serialNumber'))
    return { isValid: false, issue: 'serialNumber' };
  if (!valids.includes('isMixedTheta'))
    return { isValid: false, issue: 'isMixedTheta' };
  if (!valids.includes('isHeating'))
    return { isValid: false, issue: 'isHeating' };
  if (
    unitInfo.portConfiguration.portConfigurationType === 'regular' &&
    !valids.includes('s1 s2 s3 s4 t1 t2 t3 t4')
  )
    return { isValid: true, issue: 'portConfiguration' };
  if (!valids.includes('sizeOfPlatePlack'))
    return { isValid: false, issue: 'sizeOfPlatePlack' };

  return { isValid: true };
};

const mapStateToProps = (state, ownProps) => {
  const currentState = state.units.currentState;
  const units = getUnitsOfCurrentCustomer(state);
  const id = ownProps.match.params.id;
  const serialNumber = ownProps.match.params.sn;
  let existingData = units.find(unit => unit.id === id);
  const image = state.units.images[id];
  if (image) {
    existingData = { ...existingData, image };
  }
  const goToList = () => ownProps.history.push('/list-units');
  return {
    currentState,
    units,
    serialNumber,
    existingData,
    goToList,
  };
};

const mapDispatchToProps = dispatch => ({
  getUnitImage: id => dispatch(getUnitImage(id)),
  editUnit: unitInfo => dispatch(editUnit(unitInfo)),
  addUnit: unitInfo => dispatch(addUnit(unitInfo)),
  resetCurrentState: () => dispatch(resetCurrentState()),
  startAnalysis: unit => dispatch(startAnalysis(unit)),
  onAdd: callback => (unitInfo, valids) => {
    const { isValid, issue } = isValidInfo(valids, unitInfo);
    if (isValid) {
      if (unitInfo.id) {
        dispatch(editUnit({ unit: unitInfo, callback }));
      } else {
        dispatch(addUnit({ unit: unitInfo, callback }));
      }
    } else {
      return issue;
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUnit);
