import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  AzureSignInButton,
  AzureSignOutButton,
  ConditionalRender,
  LoadingSpinner,
} from '../../components';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.div`
  img {
    width: 250px;
  }
`;
const ButtonContainer = styled.div``;

export const Login = props => {
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated, authStatus } = useAuth();
  const signInRejected = useSelector(({ user }) => user.wasRejected);

  useEffect(
    () => isAuthenticated && history.push(location.pathname || '/home'),
    [isAuthenticated] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ConditionalRender
      predicate={!isAuthenticated && authStatus.NONE}
      fallback={
        props.errorData !== '' ? 
       <Container>An error occurred: {props.errorData} {signInRejected}
       <ButtonContainer>
           <AzureSignOutButton />
         </ButtonContainer>
         </Container>
         :
        <LoadingSpinner />
      }>
      <Container>
        <ImgContainer>
          <img src={'/logo512.png'} alt={'logo'} />
        </ImgContainer>
        <ButtonContainer>
          <AzureSignInButton />
        </ButtonContainer>
      </Container>
    </ConditionalRender>
  );
};
