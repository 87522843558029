import React from 'react';
import { useSelector } from 'react-redux';
import {
  UnitOverview,
  AnalyticsDetails,
  ListOfReports,
  UnitNotFound,
} from './../';
import styled from 'styled-components';
import { reportsPerUnit } from '../../redux/selectors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    margin-left: 14px;
    margin-right: 14px;
  }
  > button {
    width: unset;
    margin: 10px;
  }
`;

const Reports = styled.div`
  padding: 14px;
`;

export const UnitDetails = props => {
  const id = props.match.params.id;
  const unit = useSelector(state =>
    state.units.items.find(unit => unit.id === id)
  );
  const reports = useSelector(reportsPerUnit)[id];

  if (!unit) {
    return <UnitNotFound id={id} />;
  }

  return (
    <Container>
      <h2>Product details</h2>
      <UnitOverview unit={unit} history={props.history} hideButtons />
      <AnalyticsDetails unit={unit} />

      <Reports>
        <h3>All unit reports</h3>
        <ListOfReports reports={reports} unit={unit} visibleNbr={3} />
      </Reports>
    </Container>
  );
};
