import { IconFilter, IconFilterOff, IconX } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ConditionalRender, SingleOutCustomer, TextButton } from '..';
import { setCurrentCustomer } from '../../redux/customers';

const Container = styled.div`
  width: 100%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  color: var(--neutrals-60);
  background-color: var(--neutrals-20);
`;

const Key = styled.div`
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  height: 24px;
`;

const PickCustomer = styled.div`
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  > div {
    padding: 0;
    p {
      display: none;
    }

    select {
      height: 24px;
      font-size: 14px;
      margin: 0;
      width: fit-content;
      align-self: flex-start;
      margin-left: 3px;
    }
  }
`;

const Value = styled.div`
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 13px;
  text-align: left;
  height: 24px;
  font-size: 14px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  box-sizing: border-box;
`;

const CloseContainer = styled.div`
  cursor: pointer;
`;

const CloseBtn = props => (
  <CloseContainer>
    <TextButton onClickHandler={props.onClickHandler}>
      <IconX size="15" color="var(--neutrals-60)" />
    </TextButton>
  </CloseContainer>
);

const FilterContainer = styled.div`
  svg {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 20px;
    cursor: pointer;
  }
`;

const FilterBtn = props => (
  <FilterContainer>
    <ConditionalRender
      predicate={props.show}
      fallback={<IconFilter onClick={() => props.setShow(true)} size="20" />}>
      <IconFilterOff onClick={() => props.setShow(false)} size="20" />
    </ConditionalRender>
  </FilterContainer>
);

export const ShowCurrentCustomer = props => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const currentCustomer = useSelector(
    ({ customers }) => customers.items[customers.currentCustomer]
  );

  useEffect(() => setShow(false), [currentCustomer]);

  const onCloseHandler = () => {
    dispatch(setCurrentCustomer(null));
    setShow(false);
  };

  return (
    <Container show={show}>
      <Key>Customer:</Key>
      <ConditionalRender
        predicate={show}
        fallback={
          <Value>
            {currentCustomer || 'None'}
            {currentCustomer && <CloseBtn onClickHandler={onCloseHandler} />}
          </Value>
        }>
        <PickCustomer>
          <SingleOutCustomer />
          <CloseBtn onClickHandler={onCloseHandler} show={show} />
        </PickCustomer>
      </ConditionalRender>
      <FilterBtn setShow={setShow} show={show} />
    </Container>
  );
};
