import React, { useState } from 'react';
import { TextButton, ConditionalRender } from '../';
import { HeaderItem } from './styles';
import Reminders from './Reminders';

const PassedReminders = props => {
  const [showOverdue, setShowOverdue] = useState(false);
  const overdueReminders = props.items.filter(i => !i.isValid);
  const nbr = overdueReminders.length;
  return (
    <ConditionalRender predicate={nbr > 0}>
      <>
        <HeaderItem>
          <h4>{`${nbr} Overdue ${nbr === 1 ? 'reminder' : 'reminders'}`}</h4>
          <TextButton onClickHandler={() => setShowOverdue(prev => !prev)}>
            {showOverdue ? `Hide overdue reminders` : `Show overdue reminders`}
          </TextButton>
        </HeaderItem>
        <ConditionalRender predicate={showOverdue}>
          <Reminders
            items={overdueReminders}
            toggleExpanded={props.toggleExpanded}
            expanded={props.expanded}
          />
        </ConditionalRender>
      </>
    </ConditionalRender>
  );
};

export default PassedReminders;
