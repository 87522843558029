import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MainButton } from '../../components';
import { useHistory } from 'react-router';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin: 2px 0;
    &:last-of-type {
      font-size: 40px;
      font-weight: bold;
      margin-top: 20px;
    }
  }
  button {
    margin: 20px 0;
  }
`;

const Step3 = props => {
  const history = useHistory();
  const [count, setCount] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => setCount(prev => prev - 1), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => count <= 0 && history.push('/home'), [count, history]);

  return (
    <Container>
      <h2>Great!</h2>
      <p>The analysis has been sent to us.</p>
      <p>We will get back to you when it has been reviewed.</p>
      <p>You will be redirected in</p>
      <p>{count}</p>
      <MainButton to="/home">Go back home </MainButton>
    </Container>
  );
};

export default Step3;
