import React, { useState } from 'react';
import styled from 'styled-components';
import {
  IconAlertCircle,
  IconCircleCheck,
  IconClock,
  IconCircle,
  IconReportAnalytics,
  IconChevronUp,
  IconChevronDown,
  IconRotateClockwise2,
} from '@tabler/icons';
import { ConditionalRender } from './../';
import { useHistory } from 'react-router-dom';
import { formatDate, reportHasError } from '../../helpers';

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const ReportList = styled.ul`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Report = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px 40px;
  border-left: 1px solid var(--neutrals-30);
  position: relative;
  h4 {
    font-size: 20px;
    font-weight: bold;
  }
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeLineIcon = styled.div`
  position: absolute;
  left: 0;
  top: 7px;
  transform: translateX(-50%);
  background: ${props => props.background || 'var(--white)'};
  padding: 5px 0;
`;

const ReportInformation = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const CreationDate = styled.div`
  display: flex;
  align-items: center;
  color: var(--neutrals-40);
  padding: 5px 0;
  svg {
    margin-right: 5px;
  }
`;

const Timestamp = styled.div`
  display: flex;
  align-items: center;
  color: var(--neutrals-40);
  padding: 5px 0;
  svg {
    margin-right: 5px;
  }
`;

const ReportListTopItem = styled.li`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 40px;
  border-left: 1px solid var(--neutrals-30);
  position: relative;
  > div:first-of-type {
    top: 0;
    svg {
      padding-top: 23px;
      display: flex;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
  }
`;

const ShowAllButton = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  margin: 24px auto 0 auto;
  cursor: pointer;
  p {
    width: 70px;
  }
  svg {
    display: flex;
    flex-grow: 1;
  }
`;

export const ListOfReports = props => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(prev => !prev);
  const history = useHistory();
  return (
    <Container>
      <ReportList showAll={showAll}>
        <ReportListTopItem>
          <TimeLineIcon background={props.background}>
            <IconCircle
              size="15"
              stroke="4"
              fill={props.background || 'var(--white)'}
              color="var(--earth-secondary)"
            />
          </TimeLineIcon>
        </ReportListTopItem>
        {(props.reports || [])
          .sort(
            (a, b) => new Date(b.startTimestamp) - new Date(a.startTimestamp)
          )
          .filter((_, index) =>
            showAll ? true : index < (props.visibleNbr || props.reports.length)
          )
          .map(r => (
            <Report key={r.id}>
              <TimeLineIcon background={props.background}>
                {reportHasError(r) ? (
                  <IconAlertCircle size="30" color="var(--earth-secondary)" />
                ) : r.hotSideResult === 'Ok' && r.coldSideResult === 'Ok' ? (
                  <IconCircleCheck size="30" color="var(--blue-primary)" />
                ) : (
                  <IconRotateClockwise2 size="30" color="var(--blue-primary)" />
                )}
              </TimeLineIcon>
              <ReportInformation>
                <VerticalFlex>
                  <div>
                    <h4>{`${r.hotSideResult} / ${r.coldSideResult}`}</h4>
                  </div>
                  <Timestamp>{r.startTimestamp}</Timestamp>
                  <CreationDate>
                    <IconClock color="var(--neutrals-40)" size="15" />
                    {formatDate(r.startTimestamp)}
                  </CreationDate>
                </VerticalFlex>
                <IconReportAnalytics
                  color="var(--blue-primary)"
                  style={{ cursor: 'pointer' }}
                  size="30"
                  onClick={() => history.push(`/unit-report/${r.id}`)}
                />
              </ReportInformation>
            </Report>
          ))}
      </ReportList>
      <ConditionalRender predicate={props.visibleNbr > 0}>
        <ShowAllButton onClick={toggleShowAll}>
          <p>{showAll ? 'Show less' : 'Show all'}</p>
          {showAll ? <IconChevronUp /> : <IconChevronDown />}
        </ShowAllButton>
      </ConditionalRender>
    </Container>
  );
};
