import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { MainButton, Dialog } from '..';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    flex-shrink: 1;
    margin: 5px;
  }
`;

const Container = styled.div`
  p {
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const UnitNotFound = props => {
  const [showPrompt, togglePrompt] = useState(props.show || true);
  const history = useHistory();

  const onClose = () => {
    togglePrompt(false);
    history.push('/home');
  };

  return (
    <Container>
      <Dialog onClose={onClose} show={showPrompt}>
        <p>
          {`Unit with id `}
          <br />
          <b>{props.id}</b>
          <br />
          {` could not be found!`}
        </p>
        <ButtonContainer>
          <MainButton onClickHandler={onClose}>Go to home</MainButton>
        </ButtonContainer>
      </Dialog>
    </Container>
  );
};
