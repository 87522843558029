import React from 'react';
import styled from 'styled-components';
import { ConditionalRender } from '../../../components';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { TwoColumnGrid, Head, Body, SmallHeader } from '../styles';
import { useSelector } from 'react-redux';

const ExpansionButtonContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  margin-left: 20;
`;

const Container = styled.div`
  border: 1px solid var(--neutrals-30);
`;

const Information = styled.div`
  margin: 5px 0;
  color: var(--neutrals-70);
`;

const ExpansionButton = props => (
  <ExpansionButtonContainer onClick={props.toggleExpansion}>
    <ConditionalRender
      predicate={props.expanded}
      fallback={<IconChevronDown size="15" color="var(--blue-primary)" />}>
      <IconChevronUp size="15" color="var(--blue-primary)" />
    </ConditionalRender>
  </ExpansionButtonContainer>
);

export const GeneralInformation = props => {
  console.log(props.report);
  return (
    <Container>
      <Head onClick={() => props.toggle(prev => !prev)}>
        <h3>General information</h3>
        <ExpansionButton {...props} />
      </Head>
      <ConditionalRender predicate={props.show}>
        <Body>
          <TwoColumnGrid>
            <div>
              <SmallHeader>Date:</SmallHeader>
              <Information>{props.report.startTimestamp}</Information>
            </div>
            <div>
              <SmallHeader>Flow assessment</SmallHeader>
              <Information>{`${props.report.flowRate * 100}%`}</Information>
            </div>
            {/* Removing this field as it is hard-coded and there should not bring any end user value. */}
            {/* <div>
              <SmallHeader>Unit at steady state</SmallHeader>
              <Information>Yes</Information>
            </div> */}
          </TwoColumnGrid>
        </Body>
      </ConditionalRender>
    </Container>
  )
};
