import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconPlus } from '@tabler/icons';

import {
  DropDown,
  TextInput,
  ConditionalRender,
  TextButton,
} from '../../../components';

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
  button {
    margin: 15px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--neutrals-60);
  }
`;

const AddNewBrandInput = styled.div`
  position: relative;
  button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin: 0;
    height: 38px;
    padding: 0 20px;
    background: var(--blue-primary);
    color: white;
  }
`;

const options = [
  { label: 'Alfa Laval', value: 'alfa laval' },
  { label: 'ACC', value: 'acc' },
  { label: 'API', value: 'api' },
  { label: 'APV', value: 'apv' },
  { label: 'Cipriani', value: 'cipriani' },
  { label: 'DHP', value: 'dhp' },
  { label: 'Funke', value: 'funke' },
  { label: 'GEA', value: 'gea' },
  { label: 'HES', value: 'hes' },
  { label: 'Hisaka', value: 'hisaka' },
  { label: 'ITT', value: 'itt' },
  { label: 'Kelvion', value: 'kelvion' },
  { label: 'LHE', value: 'lhe' },
  { label: 'Sondex', value: 'sondex' },
  { label: 'THE0', value: 'the0' },
  { label: 'THE1', value: 'the1' },
  { label: 'THE2', value: 'the2' },
  { label: 'Tranter', value: 'tranter' },
];

const label = 'Brand';

const isAddUnit = () => window.location.pathname === '/add-unit';

export const Brand = props => {
  const [initialLoad, setInitialLoad] = useState(false);
  const [showTextField, setShowTextField] = useState(
    isAddUnit() ? false : true
  );

  const onChange = value => {
    const isValid = props.mandatory ? value !== null && value !== '' : true;
    setInitialLoad(true);
    props.onChange(value, isValid);
  };

  useEffect(() => {
    const showTextField = !options.find(o => o.value === props.value);
    if (!initialLoad && !showTextField) {
      setShowTextField(showTextField);
      setInitialLoad(true);
    }
  }, [props.value]);

  return (
    <Container id={props.id}>
      <ConditionalRender
        predicate={showTextField}
        fallback={
          <DropDown
            label={label}
            value={props.value}
            options={options}
            onChange={onChange}
            mandatory={props.mandatory}
            defaultOption={options.find(o => o.value === 'alfa laval')}
          />
        }>
        <AddNewBrandInput>
          <TextInput
            value={props.value}
            label={'Add new brand'}
            onChange={onChange}
          />
        </AddNewBrandInput>
      </ConditionalRender>

      <ConditionalRender
        predicate={!showTextField}
        fallback={
          <TextButton
            onClickHandler={() => {
              onChange('alfa laval');
              setShowTextField(false);
            }}>
            <IconPlus size="20" />
            Show predefined brands
          </TextButton>
        }>
        <TextButton
          onClickHandler={() => {
            onChange('');
            setShowTextField(true);
          }}>
          <IconPlus size="20" />
          Add NOT predefined brand
        </TextButton>
      </ConditionalRender>
    </Container>
  );
};
