import { IconSquare, IconSquareCheck } from '@tabler/icons';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  input {
    width: ${props => (props.size === 'large' ? '40px' : '20px')};
    height: ${props => (props.size === 'large' ? '40px' : '20px')};
    opacity: 0;
    display: flex;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    text-align: left;
  }
`;

const Input = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-right: 5px;
`;

const CustomInput = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height 100%;
  width: 100%;
  background-color: var(--white);
  box-sizing: border-box;
`;

export const Checkbox = props => (
  <Container size={props.size}>
    <Input>
      <CustomInput>
        {props.value ? (
          <IconSquareCheck size="100%" color="var(--blue-primary)" />
        ) : (
          <IconSquare size="100%" color="var(--blue-primary)" />
        )}
      </CustomInput>
      <input
        type="checkbox"
        id={props.id}
        name={props.name}
        value={props.label}
        onChange={e => props.onChange(e.target.checked)}
        defaultChecked={props.value}
      />
    </Input>
    <label htmlFor={props.id}>{props.label}</label>
  </Container>
);
