import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  RunAnalyticsButton,
  ViewProductButton,
  RemoveUnit,
  UnitImage,
  SerialNumber,
  UnitIdentity,
} from '..';
import { UnitOverviewContainer, TopInfoBox, ImageContainer } from './styles';
import { useSelector } from 'react-redux';
import StatusBox from './StatusBox';
import useMedia from '../../hooks/useMedia';
import { reportsPerUnit } from '../../redux/selectors';

export const UnitOverview = ({ unit, hideButtons }) => {
  const { isMin576 } = useMedia();
  const history = useHistory();

  const reports = useSelector(reportsPerUnit)[unit.id];
  const latestReport = reports && reports[0];
  const reminder = useSelector(state => state.reminders.items[unit.id]);

  const onRemove = () => history.push('/list-units');

  return (
    <UnitOverviewContainer>
      <TopInfoBox>
        <UnitIdentity unit={unit} lineBreaks />
        <SerialNumber>{unit.serialNumber}</SerialNumber>
        <StatusBox report={latestReport} reminder={reminder} unit={unit} />
      </TopInfoBox>
      <ImageContainer>
        <UnitImage unit={unit} size={isMin576 ? 'large' : 'medium'} />
      </ImageContainer>
      <>
        <RunAnalyticsButton id={unit.id} />
        {!hideButtons && <ViewProductButton id={unit.id} />}
        <RemoveUnit unit={unit} onRemove={onRemove} />
      </>
    </UnitOverviewContainer>
  );
};
