import React, { useEffect } from 'react';
import { FileInput } from '../..';
import styled from 'styled-components';

const Container = styled.div`
  margin: 20px 0;
`;

const label = 'Upload a hot side image';
const description = 'Click here to upload an image of the unit.';

export const UploadImageHot = props => {
  const onChange = value => {
    const isValid = props.mandatory ? value !== null : true;
    props.onChange('hotSideImage', value, isValid);
  };

  useEffect(() => props.onChange('hotSideImage', null, null), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="upload-image-hot">
      <FileInput
        label={label}
        value={props.value}
        description={description}
        onChange={onChange}
        mandatory={props.mandatory}
      />
      <p style={{ marginTop: 5, color: 'red' }} hidden="true" id="upload-image-hot-error">You have added duplicate images. Please select a new image.</p>
    </Container>
  );
};
