import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { addCustomer } from '../../../redux/customers';

import {
  DropDown,
  TextInput,
  ConditionalRender,
  TextButton,
  MainButton,
} from '../../../components';
import { IconPlus } from '@tabler/icons';

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
  button {
    margin: 15px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--neutrals-60);
  }
`;

const AddNewCustomerInput = styled.div`
  position: relative;
  button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: white;
    margin: 0;
    height: 38px;
    padding: 0 20px;
    width: auto;
    border-radius: 0;
  }
`;

const label = 'Customer';

export const Customer = props => {
  const dispatch = useDispatch();
  const [showTextField, setShowTextField] = useState(false);
  const [newCustomer, setNewCustomer] = useState('');
  const options = Object.entries(
    useSelector(state => state.customers.items)
  ).map(([value, label]) => ({ label, value }));

  const onChange = value => {
    const isValid = props.mandatory ? value !== null && value !== '' : true;
    props.onChange(value, isValid);
  };

  const onAddCustomer = e => {
    if (!e.key || e.key.toLowerCase() === 'enter') {
      dispatch(addCustomer(newCustomer));
      setShowTextField(false);
      setNewCustomer('');
    } else if (e.key.toLowerCase() === 'escape') {
      setShowTextField(false);
      setNewCustomer('');
    }
  };

  return (
    <Container id={props.id}>
      <DropDown
        label={label}
        value={props.value}
        options={options}
        onChange={onChange}
        mandatory={props.mandatory}
        disableFirst={true}
      />

      <ConditionalRender predicate={showTextField}>
        <AddNewCustomerInput>
          <TextInput
            value={newCustomer}
            label={'Add new customer'}
            onChange={setNewCustomer}
            onKeyDown={onAddCustomer}
          />
          <MainButton
            onClickHandler={onAddCustomer}
            disabled={newCustomer === ''}>
            Save
          </MainButton>
        </AddNewCustomerInput>
      </ConditionalRender>

      <ConditionalRender predicate={!showTextField}>
        <TextButton onClickHandler={() => setShowTextField(true)}>
          <IconPlus size="20" />
          Add customer
        </TextButton>
      </ConditionalRender>
    </Container>
  );
};
