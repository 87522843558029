import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  ConditionalRender,
  DateAndTimePicker,
  MainButton,
  MinimalPresentationalUnit,
  SecondaryButton,
} from '../../components';
import { List, Body, Head } from './styles';

import ListHeader from './ListHeader';
import { BodyButtons, Expansion, Reminder, UnitsGroupHeader } from './columns';
import { useDispatch } from 'react-redux';
import { addReminder } from '../../redux/reminders';

const Container = styled.div`
  ul li > div {
    grid-template-columns: 3fr 1fr 50px;
    @media (min-width: 576px) {
      grid-template-columns: 2fr 1fr 50px;
    }
  }
`;

const listHeaderOptions = [
  {
    label: 'Heat exchanger',
    value: 'name',
  },
  {
    label: 'Reminder',
    value: 'reminder',
  },
];

const BulkRemindersContainer = styled.div`
  display: flex;
  border: 1px solid var(--neutrals-40);
  margin-top: 10px;
  border-radius: 5px;
  background-color: var(--neutrals-10);
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0px 1px 2px 0px var(--neutrals-30);
  > div {
    display: flex;
    > p {
      margin-bottom: 5px;
      font-size: 16px;
      color: var(--neutrals-60);
      padding-bottom: 5px;
    }
    button {
      padding: 10px 10px;
    }
  }

  input {
    margin: 5px 0 10px 0;
    color: var(--neutrals-60);
  }

  button {
    width: fit-content;
    padding: 0;

    &:nth-of-type(2) {
      margin-left: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const BulkReminders = ({ enabled, setEnabled, items, setItems }) => {
  const [timeStamp, setTimeStamp] = useState('');
  const dispatch = useDispatch();
  const enable = () => setEnabled(true);
  const disable = () => {
    setEnabled(false);
    setItems([]);
  };

  const onConfirm = () => {
    items.forEach(unit =>
      dispatch(addReminder({ heatExchangerId: unit.id, timeStamp }))
    );
    disable();
  };
  return (
    <BulkRemindersContainer>
      <div>
        <ConditionalRender
          predicate={enabled}
          fallback={
            <MainButton onClickHandler={enable}>Set bulk reminders</MainButton>
          }>
          <div>
            <p>SELECT UNITS AND SET A COMMON REMINDER</p>
            <DateAndTimePicker onChange={setTimeStamp} value={timeStamp} />
            <ButtonContainer>
              <MainButton onClickHandler={onConfirm}>Confirm</MainButton>
              <SecondaryButton onClickHandler={disable}>Cancel</SecondaryButton>
            </ButtonContainer>
          </div>
        </ConditionalRender>
      </div>
    </BulkRemindersContainer>
  );
};

const RemindersList = ({
  setSortAttr,
  sortAttr,
  units,
  toggleExpanded,
  expanded,
  groupUnitsBy,
}) => {
  const [bulkReminderMode, setBulkReminderMode] = useState(false);
  const [bulkOfUnits, setBulkOfUnits] = useState([]);

  const addToBulk = unit => val => {
    if (!bulkReminderMode) {
      return;
    }
    val
      ? setBulkOfUnits(prev => [...prev, unit])
      : setBulkOfUnits(prev => prev.filter(u => u.id !== unit.id));
  };

  return (
    <Container>
      <BulkReminders
        items={bulkOfUnits}
        setItems={setBulkOfUnits}
        enabled={bulkReminderMode}
        setEnabled={setBulkReminderMode}
      />
      <List>
        <ListHeader
          setSortAttr={setSortAttr}
          sortAttr={sortAttr}
          options={listHeaderOptions}
        />

        {units.map((unit, index) => (
          <React.Fragment key={unit.id}>
            <UnitsGroupHeader
              groupUnitsBy={groupUnitsBy}
              index={index}
              unit={unit}
              units={units}
            />
            <li>
              <Head
                onClick={
                  bulkReminderMode
                    ? () =>
                        addToBulk(unit)(
                          !bulkOfUnits.find(u => u.id === unit.id)
                        )
                    : () => toggleExpanded(index)
                }>
                <MinimalPresentationalUnit unit={unit} />
                <Reminder reminder={unit.reminder} />
                <ConditionalRender
                  predicate={bulkReminderMode}
                  fallback={<Expansion expanded={expanded} index={index} />}>
                  <Checkbox
                    value={bulkOfUnits.find(u => u.id === unit.id)}
                    size="large"
                    onChange={addToBulk(unit)}
                  />
                </ConditionalRender>
              </Head>
              <Body show={expanded === index}>
                <BodyButtons id={unit.id} />
              </Body>
            </li>
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default RemindersList;
