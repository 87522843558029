import React from 'react';
import styled from 'styled-components';
import { FormLabel } from './../';

const Example = styled.p`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  font-size: 16px;
  color: var(--neutrals-40);
  padding: 12px;
  user-select: none;
  pointer-events: none;
`;

const CustomInput = styled.input`
  font-weight: 400;
  line-height: 24px;
  color: var(--neutrals-60);
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  height: 38px;
  background: var(--white);
  border: 1px solid var(--neutrals-30);
  box-sizing: border-box;
  border-radius: 4px;
  flex-grow: 0;
  margin: 5px 0px;
`;

export const TextInput = props => (
  <>
    <FormLabel>{props.mandatory ? `${props.label} *` : props.label}</FormLabel>
    <Example show={!props.value || props.value === ''}>
      {props.exampleText}
    </Example>
    <CustomInput
      type={props.type === 'password' ? 'password' : 'text'}
      value={props.value || ''}
      name={props.label}
      onChange={e => props.onChange(e.target.value)}
      onKeyDown={props.onKeyDown}
    />
  </>
);
