import styled from 'styled-components';

export const ReminderHeader = styled.div`
  display: flex;
  align-items: center;
  color: var(--neutrals-60);
  > svg {
    margin-right: 5px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background: var(--blue-primary);
  box-sizing: border-box;
  align-items: center;
  flex-basis: 100%;
  h3 {
    margin: 20px 0;
    color: var(--white);
  }
  @media (min-width: 576px) {
    grid-column: 2 span;
  }
  @media (min-width: 768px) {
    grid-column: 4 span;
  }
  @media (min-width: 1024px) {
    grid-column: 6 span;
  }
`;

export const ScrollContainer = styled.div`
  height: auto;
  overflow: scroll;
  border: 1px solid var(--blue-primary);
  max-width: 450px;
  width: 100%;
  ul {
    li {
      cursor: pointer;
      background-color: var(--white);
      padding: 10px;
      @media (min-width: 480px) {
        padding: 10px 20px;
      }
      border-bottom: 1px solid var(--neutrals-50);

      &:first-of-type {
        border-radius: 10px 10px 0 0;
      }

      &:last-of-type {
        border-bottom: none;
        border-radius: 0 0 10px 10px;
      }
    }
  }
`;

export const HeaderItem = styled.li`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  f button {
    margin: 0;
    padding: 0;
    text-decoration: underline;
  }
`;

export const TwoColumnFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  align-items: flex-end;
  div {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    margin: 5px 0;
  }
`;

export const Body = styled.div`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  padding: 10px 0;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  button {
    flex-grow: 0;
    flex-shrink: 1;
    width: 50%;
    &:first-of-type {
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      margin-left: 5px;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 450px;
  svg {
    margin-right: 5px;
  }
`;

export const PreviousCheck = styled.div`
  color: var(--neutrals-40);
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  svg {
    margin-right: 5px;
  }
`;

export const NoneContainer = styled.li`
  padding: 10px;
  background-color: var(--white);
`;
