import { useMediaPredicate } from 'react-media-hook';

import React, { useContext } from 'react';

const Context = React.createContext(null);

export const MediaProvider = props => (
  <Context.Provider
    value={{
      isMax319: useMediaPredicate('(max-width: 319px)'),
      isMin320: useMediaPredicate('(min-width: 320px)'),
      isMax479: useMediaPredicate('(max-width: 479px)'),
      isMin480: useMediaPredicate('(min-width: 480px)'),
      isMax575: useMediaPredicate('(max-width: 575px)'),
      isMin576: useMediaPredicate('(min-width: 576px)'),
      isMax767: useMediaPredicate('(max-width: 767px)'),
      isMin768: useMediaPredicate('(min-width: 768px)'),
      isMax1023: useMediaPredicate('(max-width: 1023px)'),
      isMin1024: useMediaPredicate('(min-width: 1024px)'),
    }}>
    {props.children}
  </Context.Provider>
);

const useMedia = () => useContext(Context);

export default useMedia;
