export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      'https://login.microsoftonline.com/ed5d5f47-52dd-48af-90ca-f7bd83624eb9/', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_SCOPE],
};
