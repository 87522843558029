import React from 'react';
import styled from 'styled-components';

import { TextInput } from '../../../components';

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
`;

const label = 'Process type';

export const ProcessType = props => {
  const onChange = value => {
    const isValid = props.mandatory ? value !== null && value !== '' : true;
    props.onChange(value, isValid);
  };

  return (
    <Container id={props.id}>
      <TextInput
        value={props.value}
        label={label}
        onChange={onChange}
        mandatory={props.mandatory}
      />
    </Container>
  );
};
