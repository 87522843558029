import styled from 'styled-components';

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 5px 20px;
  height: 50px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  button {
    margin: 5px 0;
  }
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  width: 100%;
  > div {
    width: 100%;
    > svg {
      width: 100%;
    }
  }
`;

export const SmallHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--neutrals-70);
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const PlaceHolder = styled.div`
  background-color: var(--neutrals-10);
  border-radius: 10px;
  border: 1px solid var(--neutrals-20);
`;
