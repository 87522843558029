import React from 'react';
import styled from 'styled-components';
import { MinimalPresentationalUnit, ListOfReports } from '../../components';
import { Body, List, Head } from './styles';

import ListHeader from './ListHeader';
import {
  BodyButtons,
  Expansion,
  ReportDate,
  UnitsGroupHeader,
} from './columns';

const Container = styled.div`
  margin-top: 24px;
  ul li > div {
    grid-template-columns: 2fr 0.9fr 35px;
    @meida (min-width: 576px) {
      grid-template-columns: 2fr 1fr 35px;
    }
  }
`;

const listHeaderOptions = [
  {
    label: 'Heat exchanger',
    value: 'name',
  },
  {
    label: 'Report',
    value: 'latestReportResult',
  },
];

const StatusList = ({
  setSortAttr,
  sortAttr,
  units,
  toggleExpanded,
  expanded,
  groupUnitsBy,
}) => {
  return (
    <Container>
      <List>
        <ListHeader
          setSortAttr={setSortAttr}
          sortAttr={sortAttr}
          options={listHeaderOptions}
        />
        {units.map((unit, index) => (
          <React.Fragment key={unit.id}>
            <UnitsGroupHeader
              groupUnitsBy={groupUnitsBy}
              index={index}
              unit={unit}
              units={units}
            />
            <li>
              <Head onClick={() => toggleExpanded(index)}>
                <MinimalPresentationalUnit unit={unit} />
                <ReportDate reports={unit.reports} />
                <Expansion expanded={expanded} index={index} />
              </Head>
              <Body show={expanded === index}>
                <BodyButtons id={unit.id} />
                <ListOfReports
                  background="var(--neutrals-10)"
                  reports={unit.reports}
                  unit={unit}
                />
              </Body>
            </li>
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default StatusList;
