import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reportToErrorQueue } from './errors';

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (_, { extra: API, dispatch }) =>
    API.getCustomers().catch(
      reportToErrorQueue(dispatch, {
        executor: 'customers',
        issue: 'get',
        payload: null,
      })
    )
);

export const addCustomer = createAsyncThunk(
  'customers/addCustomer',
  async (name, { extra: API, dispatch }) =>
    API.createCustomer({ name }).catch(
      reportToErrorQueue(dispatch, {
        executor: 'customers',
        issue: 'create',
        payload: { name },
      })
    )
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState: { items: [], fetched: false, currentCustomer: null },
  reducers: {
    setCurrentCustomer: (state, action) => ({
      ...state,
      currentCustomer: action.payload,
    }),
  },
  extraReducers: {
    [fetchCustomers.fulfilled]: (state, { payload }) => ({
      ...state,
      fetched: true,
      items: payload.customers.reduce(
        (acc, c) => ({
          ...acc,
          [c.id]: c.name,
        }),
        {}
      ),
    }),
    [addCustomer.fulfilled]: (state, { payload }) => ({
      ...state,
      items: { ...state.items, [payload.customer.id]: payload.customer.name },
    }),
  },
});

export const { setCurrentCustomer } = customersSlice.actions;
export default customersSlice.reducer;
