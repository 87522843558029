import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { createAnalysis } from '../../redux/analyses';
import { getUnitsOfCurrentCustomer } from '../../redux/selectors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  > ul {
    margin-bottom: 51px;
  }

  @media (min-width: 768px) {
    width: 768px;
    padding: 20px;
  }
`;

const getStep = url => (url ? Number(url.match(/step-([0-9])/)[1]) : null);

export const AnalyseUnits = props => {
  const [units, setUnits] = useState([]);
  const step = getStep(props.match.params.step);
  const dispatch = useDispatch();

  const allUnits = useSelector(getUnitsOfCurrentCustomer);
  const history = useHistory();

  const onDone = arr => () => {
    arr.forEach(data => dispatch(createAnalysis(data)));
    history.push(`/analyse-units/step-${step + 1}`);
  };

  const onNextClick = () => history.push(`/analyse-units/step-${step + 1}`);
  useEffect(() => history.push(`/analyse-units/step-1`), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Switch>
        <Route path="/analyse-units/step-1">
          <Step1
            units={units}
            setUnits={setUnits}
            onNextClick={onNextClick}
            allUnits={allUnits}
          />
        </Route>
        <Route path="/analyse-units/step-2">
          <Step2 units={units} onDone={onDone} />
        </Route>
        <Route path="/analyse-units/step-3">
          <Step3 units={units} />
        </Route>
      </Switch>
    </Container>
  );
};
