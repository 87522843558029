import styled from 'styled-components';

export const ImageContainer = styled.div`
  img {
    max-width: 100%;
  }
`;
export const OrderEquipment = styled.div`
  img {
    max-width: 100%;
  }
  padding: 28px 0;

  @media (min-width: 576px) {
    grid-column: 2 span;
  }

  @media (min-width: 768px) {
    grid-column: 4 span;
  }

  @media (min-width: 1024px) {
    grid-column: 2 span;
  }
`;

export const ButtonList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 120px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 0 auto;
  max-width: 310px;

  @media (min-width: 480px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 10px;
    max-width: unset;
  }

  @media (min-width: 576px) {
    grid-column: 2 span;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row: 1 span;
    grid-auto-rows: 153px;
    margin: auto;
    width: 100%;
    padding: 0;
  }

  @media (min-width: 768px) {
    display: none;
  }

  li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--blue-primary);
    border-radius: 10px;
    justify-content: center;
    button {
      color: var(--blue-primary);
      margin: 5px;
      width: 100%;
      display: flex;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      align-items: center;
      a {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
          margin: 5px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  > div {
    &:nth-of-type(3) {
      padding: 20px;
    }
  }
  h1 {
    text-align: center;
    padding: 0 20px;
    flex-basis: 100%;
    margin: 40px 0;
  }
`;
