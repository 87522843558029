import React from 'react';
import styled from 'styled-components';
import { DropDown } from '../../components';

const FiltersContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const GroupFilters = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutrals-40);
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 10px;
  background-color: var(--neutrals-10);
  flex-basis: 100%;
  justify-content: flex-start;
  box-shadow: 0px 1px 2px 0px var(--neutrals-30);

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 480px) {
    margin-top: 0;
    ul {
      flex-wrap: unset;
    }
  }

  select {
    min-width: 136px;
  }

  @media (min-width: 768px) {
    width: unset;
  }

  &:first-of-type {
    margin: 0;
  }

  p,
  label {
    color: var(--neutrals-60);
    padding-bottom: 5px;
  }
  > p {
    margin-bottom: 5px;
    font-size: 16px;
  }
`;

const FiltersBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ListTypeButton = styled.li`
  padding: 8px;
  border-radius: 5px;
  background-color: var(--neutrals-20);
  border: 1px solid var(--neutrals-30);
  cursor: pointer;
  color: var(--neutrals-60);
  margin: 5px 2px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type: {
    margin-right: 0;
  }
  ${({ active }) =>
    active &&
    `
    background-color: var(--blue-primary);
    color: var(--white);
  §`}
`;

const Filters = ({ groupUnitsBy, setGroupUnitsBy, onListTypeClick }) => {
  return (
    <FiltersContainer>
      <FiltersBox>
        <GroupFilters>
          <p>SORT BY CATEGORY</p>
          <DropDown
            value={groupUnitsBy}
            id="group-units-by"
            name="group-units-by"
            onChange={value => setGroupUnitsBy(value === 'none' ? null : value)}
            options={[
              { value: 'customer', label: 'Customer' },
              { value: 'location', label: 'Location' },
              { value: 'latestReportResult', label: 'Analysis result' },
              { value: 'brand', label: 'Brand' },
            ]}
          />
        </GroupFilters>
        <GroupFilters>
          <p>LIST TYPE</p>
          <ul>
            {[
              { value: '/list-units/overview', label: 'Overview' },
              { value: '/list-units', label: 'Basic' },
              { value: '/list-units/status', label: 'Status' },
              { value: '/list-units/reminders', label: 'Reminder' },
            ].map(({ value, label }) => (
              <ListTypeButton
                key={label}
                onClick={() => onListTypeClick(value)}
                active={window.location.pathname === value}>
                {label}
              </ListTypeButton>
            ))}
          </ul>
        </GroupFilters>
      </FiltersBox>
    </FiltersContainer>
  );
};

export default Filters;
