import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { loginRequest } from '../../authConfig';
import styled from 'styled-components';

function handleLogout(instance) {
  instance.logoutRedirect(loginRequest).catch(e => {
    console.error(e);
  });
}

const ButtonContainer = styled.div`
  align-items: flex-end;
  justify-content: center;
  display: flex;
  justify-self: flex-end;
`;

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const AzureSignOutButton = () => {
  const { instance } = useMsal();
  return (
    <ButtonContainer>
      <Button
        variant="secondary"
        className="ml-auto"
        onClick={() => handleLogout(instance)}>
        Sign out
      </Button>
    </ButtonContainer>
  );
};
