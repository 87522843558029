import React from 'react';
import { RadioButtons } from '../../../components';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
`;

const options = [
  { value: true, label: 'Heating' },
  { value: false, label: 'Cooling' },
];

export const Usage = props => {
  const onChange = value => {
    const isValid = props.mandatory ? value !== null : true;
    props.onChange(value, isValid);
  };

  return (
    <Container id={props.id}>
      <RadioButtons
        label="Used for heating or cooling"
        onChange={onChange}
        value={props.value}
        options={options}
        name="usage"
        mandatory={props.mandatory}
      />
    </Container>
  );
};
