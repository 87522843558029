import React from 'react';
import styled from 'styled-components';
import { RadioButtons } from '../../../components';

const options = [
  { value: 'regular', img: 'assets/configuration_port_1.png' },
  { value: 'condenser', img: 'assets/configuration_port_2.png' },
  { value: 'evaporator', img: 'assets/configuration_port_3.png' },
];

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
  > img {
    width: 100px;
  }
`;

const label = 'Type';

export const Type = props => {
  const onChange = value => {
    const isValid = props.mandatory ? value !== null : true;
    props.onChange(value, isValid);
  };

  return (
    <Container id={props.id}>
      <RadioButtons
        onChange={onChange}
        value={props.value}
        label={label}
        options={options}
        img={true}
        name="type"
        mandatory={props.mandatory}
      />
    </Container>
  );
};
