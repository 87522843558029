import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { removeUnit } from '../../redux/units';
import {
  MainButton,
  SecondaryButton,
  TextButton,
  ConditionalRender,
  Dialog,
} from '..';
import { IconTrash } from '@tabler/icons';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    flex-shrink: 1;
    margin: 5px;
  }
`;

const RemoveUnitContainer = styled.div`
  > button {
    padding: 10px 20px;
  }
  text-align: center;
  p {
    padding: 10px;
  }
`;

export const RemoveUnit = props => {
  const [showPrompt, togglePrompt] = useState(false);
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(removeUnit(props.unit.id));
    togglePrompt(false);
  };
  const onDecline = () => togglePrompt(false);

  return (
    <RemoveUnitContainer className="remove-unit-btn">
      <ConditionalRender predicate={showPrompt}>
        <Dialog show={showPrompt} onClose={onDecline}>
          <p>{`Do you want to remove unit "${props.unit.name}"?`}</p>
          <ButtonContainer>
            <MainButton onClickHandler={onConfirm}>Yes</MainButton>
            <SecondaryButton onClickHandler={onDecline}>No</SecondaryButton>
          </ButtonContainer>
        </Dialog>
      </ConditionalRender>
      <TextButton onClickHandler={() => togglePrompt(true)}>
        <IconTrash />
      </TextButton>
    </RemoveUnitContainer>
  );
};
