import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropDown, FormLabel } from '../../../components';
import { IconInfoSquare } from '@tabler/icons';
import inlets_outlets_front from '../../../assets/inlets_outlets_front.png';
import inlets_outlets_back from '../../../assets/inlets_outlets_back.png';

const COLD = 'Cold';
const HOT = 'Hot';
const IN = 'Inlet';
const OUT = 'Outlet';

const HOT_IN = `${HOT}${IN}`;
const HOT_OUT = `${HOT}${OUT}`;
const COLD_IN = `${COLD}${IN}`;
const COLD_OUT = `${COLD}${OUT}`;

const customOptions = [
  { value: HOT_IN, label: 'Hot inlet' },
  { value: HOT_OUT, label: 'Hot outlet' },
  { value: COLD_IN, label: 'Cold inlet' },
  { value: COLD_OUT, label: 'Cold outlet' },
];

const Information = styled.div`
  font-size: 12px;
  padding-top: 5px;
  background-color: var(--neutrals-05);
  border-radius: 5px;
  padding: 5px;
  margin: 2px;

  p {
    display: contents;
    float: right
  }
`

const PortContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 45%;
  }
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
  > img {
    width: 100px;
  }
`;

const initialState = {
  s1: 'NoPort',
  s2: 'NoPort',
  s3: 'NoPort',
  s4: 'NoPort',

  t1: 'NoPort',
  t2: 'NoPort',
  t3: 'NoPort',
  t4: 'NoPort',
};

const invert = x =>
  x === COLD
    ? HOT
    : x === HOT
    ? COLD
    : x === IN
    ? OUT
    : x === OUT
    ? IN
    : 'NoPort';

const dirInvPort = port =>
  `${port[0]}${
    Number(port[1]) === 1 || Number(port[1]) === 3
      ? Number(port[1]) + 1
      : Number(port[1]) - 1
  }`;

const getNewDirection = (p, obj) => {
  const portInv = dirInvPort(p);
  const invPortDir =
    isSet(obj[portInv]) && obj[portInv].match(/(Inlet|Outlet)/)[1];
  return invert(invPortDir);
};

const isSet = x => x && x !== 'NoPort';

const getNewTemperature = (p, obj) =>
  (Number(p[1]) < 3 ? ['s1', 's2', 't1', 't2'] : ['s3', 's4', 't3', 't4'])
    .map(p => isSet(obj[p]) && obj[p].match(/(Hot|Cold)/)[1])
    .find(isSet) ||
  (Number(p[1]) < 3 ? ['s3', 's4', 't3', 't4'] : ['s1', 's2', 't1', 't2'])
    .map(p => isSet(obj[p]) && obj[p].match(/(Hot|Cold)/)[1])
    .map(invert)
    .find(isSet) ||
  'NoPort';

const isColdPair = pair => [COLD_IN, COLD_OUT].every(x => pair.includes(x));
const isHotPair = pair => [HOT_IN, HOT_OUT].every(x => pair.includes(x));
const isAny = (t, arr) => arr.filter(isSet).some(x => x.includes(t));

const validate = state => {
  if (isHotPair([state.s1, state.s2])) {
    if (isAny('Cold', [state.t1, state.t2])) {
      // Not allowed to have cold on same side as hot
      return false;
    }
    if (isColdPair([state.s3, state.s4]) || isColdPair([state.t3, state.t4])) {
      return true;
    }
  }
  if (isColdPair([state.s1, state.s2])) {
    if (isAny('Hot', [state.t1, state.t2])) {
      // Not allowed to have hot on same side as cold
      return false;
    }
    if (isHotPair([state.s3, state.s4]) || isHotPair([state.t3, state.t4])) {
      return true;
    }
  }

  return false;
};

export const InletsOutlets = props => {
  const [state, setState] = useState({ ...initialState, ...props.value });

  const onChange = key => value =>
    setState({ ...state, [key]: value === 'NoPort' ? 'NoPort' : value });

  useEffect(() => {
    const isValid = validate(state);
    props.onChange(state, isValid, 's1 s2 s3 s4 t1 t2 t3 t4');
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDisabledOptions = port => {
    const t = getNewTemperature(port, state);
    const d = getNewDirection(port, state);

    const options = [HOT_IN, HOT_OUT, COLD_IN, COLD_OUT].filter(
      x => ![t, d].filter(isSet).every(y => x.includes(y))
    );

    const x = ['s1', 's2', 's3', 's4', 't1', 't2', 't3', 't4']
      .filter(p => p !== port)
      .map(p => state[p])
      .filter(x => x !== 'NoPort' && x !== HOT_IN);

    const y = ['s1', 's2', 's3', 's4', 't1', 't2', 't3', 't4']
      .filter(p => p !== port)
      .filter(p => state[p] === HOT_IN);

    if (y.length === 2) {
      return [...options, ...x, HOT_IN];
    } else if (y.length === 1 && port[1] !== y[0][1]) {
      return [...options, ...x, HOT_IN];
    }

    return [...options, ...x];
  };

  return (
    <Container id={props.id}>
      <FormLabel>Choose inlets and outlets</FormLabel>
              
      <Information className='hide-on-small-screen'>
          <IconInfoSquare style={{float: 'left'}} />
          When using keyboard, go to desired dropdown and hit <code>&lt;Enter&gt;</code> to open selection. To reset a choice, select 'No port', then remake the seleciton.
        </Information>
      <FormLabel>Front</FormLabel>
      <img
        src={inlets_outlets_front}
        alt="inlets and outlets front"
      />
      <PortContainer>
        {['s4', 's1', 's3', 's2'].map(p => (
          <div key={p}>
            <DropDown
              defaultOption={{ value: 'NoPort', label: 'No port' }}
              disableFirst={false}
              label={p}
              options={customOptions}
              disabledOptions={getDisabledOptions(p)}
              onChange={onChange(p)}
              value={state[p]}
              mandatory
            />
          </div>
        ))}
      </PortContainer>

      <FormLabel>Back</FormLabel>
      <img src={inlets_outlets_back} alt="inlets and outlets back" />
      <PortContainer>
        {['t1', 't4', 't2', 't3'].map(p => (
          <div key={p}>
            <DropDown
              defaultOption={{ value: 'NoPort', label: 'No port' }}
              disableFirst={false}
              label={p}
              options={customOptions}
              disabledOptions={getDisabledOptions(p)}
              onChange={onChange(p)}
              value={state[p]}
              mandatory
            />
          </div>
        ))}
      </PortContainer>
    </Container>
  );
};
