import React from 'react';
import styled from 'styled-components';
import { IconCircleDotted } from '@tabler/icons';
import { ConditionalRender } from '..';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  ${({ fullPage }) =>
    fullPage &&
    `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    `}

  svg {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const size = {
  small: 30,
  medium: 40,
  large: 50,
};

export const LoadingSpinner = props => (
  <Container {...props}>
    <ConditionalRender predicate={props.backDrop}>
      <BackDrop />
    </ConditionalRender>
    <IconCircleDotted size={size[props.size] || size.small} />
  </Container>
);
