import React from 'react';
import { RadioButtons, FormLabel } from '../../../components';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
`;

const options = [
  { value: 'true', label: 'Mixed theta' },
  { value: 'false', label: 'Unmixed theta' },
  { value: 'unknown', label: 'Unknown' },
];
const label = 'Configuration theta';
export const ConfigurationTheta = props => {
  const onChange = value => {
    const isValid = props.mandatory
      ? value === 'true' || value === 'false' || value === 'unknown'
      : true;
    props.onChange(value, isValid);
  };
  return (
    <Container id={props.id}>
      <FormLabel>{props.mandatory ? `${label} *` : label}</FormLabel>
      <RadioButtons
        onChange={onChange}
        value={props.value}
        options={options}
        name="configurationTheta"
        mandatory={props.mandatory}
      />
    </Container>
  );
};
