import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const signIn = createAsyncThunk(
  'user/signIn',
  async (_, { extra: API }) => API.signUp()
);

export const userSlice = createSlice({
  name: 'user',
  initialState: { signedIn: false, signingIn: false, isLoading: true, wasRejected: false },
  reducers: {},
  extraReducers: {
    [signIn.pending]: state => {
      return { ...state, signedIn: false, signingIn: true, isLoading: true, wasRejected: false };
    },
    [signIn.fulfilled]: (state, { payload }) => {
      return { ...state, signedIn: true, signingIn: false, isLoading: false, wasRejected: false };
    },
    [signIn.rejected]: state => {
      return { ...state, signedIn: false, signingIn: false, isLoading: false, wasRejected: true };
    },
  },
});

// export const {} = userSlice.actions;
export default userSlice.reducer;
