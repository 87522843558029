import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: var(--neutrals-70);
  margin: 3px 0;
  line-height: 20px;
  p {
    margin: 5px 0;
  }
`;

export const UnitIdentity = ({ unit, lineBreaks }) => {
  const customers = useSelector(state => state.customers.items);
  const separator = lineBreaks ? <br /> : ', ';
  return (
    <Container>
      <p style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '18px' }}>
        {unit.name.toUpperCase()}
      </p>
      {unit.location && unit.location}
      {unit.location && separator}
      {customers[unit.customerId]}
    </Container>
  );
};
