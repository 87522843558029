import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Form from './Form';
import { MainButton, BackButton } from '../../components';
import { useSelector } from 'react-redux';
import { isObject } from '../../helpers';

const Container = styled.div`
  padding: 20px 0;
  margin: 0 auto;

  @media (min-width: 576px) {
    width: 536px;
  }

  .error-marking {
    -webkit-animation: blink 1s ease-in-out infinite;
    -moz-animation: blink 1s ease-in-out infinite;
    -ms-animation: blink 1s ease-in-out infinite;
    -o-animation: blink 1s ease-in-out infinite;
    animation: blink 1s ease-in-out infinite;
  }

  .static-error {
    box-shadow: 0px 0px 5px 2px red;
  }

  @keyframes blink {
    0% {
      box-shadow: 0px 0px 0px 0px red;
    }
    50% {
      box-shadow: 0px 0px 5px 2px red;
    }
    100% {
      box-shadow: 0px 0px 0px 0px red;
    }
  }

  @-webkit-keyframes blink {
    0% {
      box-shadow: 0px 0px 0px 0px red;
    }
    50% {
      box-shadow: 0px 0px 5px 2px red;
    }
    100% {
      box-shadow: 0px 0px 0px 0px red;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px;
  button {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const initialUnitInfo = {
  portConfiguration: {},
  brand: 'alfa laval'
};

const AddUnit = props => {
  const { units, existingData, serialNumber, goToList, startAnalysis, onAdd } =
    props;
  const [unitInfo, setUnitInfo] = useState(initialUnitInfo);
  const [shouldAnalyse, setShouldAnalyse] = useState(false);
  const [valids, setValids] = useState([]);
  const [updateSent, setUpdateSent] = useState(Number.MAX_SAFE_INTEGER);
  const stateUpdated =
    useSelector(state => state.units.currentState.success) > updateSent;

  const onChange = (key, subKey) => (value, isValid, validKey) => {
    const el = document.querySelector(`#question-${key}`);
    if(el !== undefined && el !== null){
      el.classList?.remove('static-error');
    }

    if(key === 'sizeOfPlatePlack'){
      value = Number(value);
    }

    setUnitInfo(prev => ({
      ...prev,
      [key]: subKey ? { ...prev[key], [subKey]: value } : value,
    }));

    validKey = validKey || subKey || key;
    if (isValid && !valids.includes(validKey)) {
      setValids(prev => [...prev, validKey]);
    } else if (!isValid && valids.includes(validKey)) {
      const index = valids.indexOf(validKey);
      if (index > -1) {
        setValids(prev => [
          ...prev.slice(0, index),
          ...prev.slice(index + 1, prev.length),
        ]);
      }
    }

  };

  const loadExistingData = () => async () => {
    if (existingData) {
      if (typeof existingData.image === 'undefined') {
        props.getUnitImage(existingData.id);
      }
      Object.keys(existingData).forEach(key => {
        if (isObject(existingData[key])) {
          Object.keys(existingData[key]).forEach(subKey => {
            onChange(key, subKey)(existingData[key][subKey], true);
          });
        } else {
          onChange(key)(existingData[key], true);
        }
      });
    } else if (serialNumber) {
      onChange('serialNumber')(serialNumber, true);
    }
  };

  const runAnalysis = () => {
    if (shouldAnalyse && stateUpdated) {
      const id = existingData ? unitInfo.id : units[units.length - 1].id;
      startAnalysis(id);
      goToList();
    }
  };

  const questionList = [
    'name',
    'brand',
    'customerId',
    'portConfigurationType',
    'serialNumber',
    'isMixedTheta',
    'isHeating',
    'sizeOfPlatePlack',
    'location',
    's1 s2 s3 s4 t1 t2 t3 t4'
  ]

  const handleIssue = issue => {
    let firstError = '';
    questionList.filter(x => !valids.includes(x)).forEach(question => {
      if(firstError === ''){
        firstError = question;
      }
      const el = document.querySelector(`#question-${question}`);
      if(el !== undefined && el !== null) {
        el.classList.add('static-error');
      }
    });
    const el = document.querySelector(`#question-${firstError}`);
    if(el !== undefined && el !== null) {
      const y = el.getBoundingClientRect().top + window.pageYOffset - 200;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    
    // setTimeout(() => {
    //   el.classList.remove('error-marking');
    // }, 3000);
  };

  useEffect(loadExistingData(), [existingData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(runAnalysis, [shouldAnalyse, stateUpdated]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(()=>{
      setValids(prev => [...prev, 'brand']);
  },[])

  return (
    <>
      <BackButton to="/home">Home</BackButton>
      <Container>
        <Form unitInfo={unitInfo} onChange={onChange} />
        <ButtonContainer>
          {/* <MainButton
            onClickHandler={() => {
              const issue = onAdd(goToList)(unitInfo, valids);
              issue && handleIssue(issue);
            }}>
            Save unit
          </MainButton> */}
          <MainButton
            onClickHandler={() => {
              setUpdateSent(new Date().getTime());
              setShouldAnalyse(true);
              const issue = onAdd()(unitInfo, valids);
              issue && handleIssue(issue);
            }}>
            Save Unit & Upload thermal images
          </MainButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default AddUnit;
