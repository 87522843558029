import React, { useEffect } from 'react';
import { Slider } from '../..';
import styled from 'styled-components';

const Container = styled.div`
  margin: 20px 0;
  input {
    width: 100%;
  }
`;

const label = 'Assessment of flow rate';

export const FlowRate = props => {
  const onChange = value => {
    const isValid = props.mandatory ? value !== null : true;
    props.onChange('flowRate', Math.round(value * 100) / 10000, isValid);
  };

  useEffect(() => props.onChange('flowRate', null, null), []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Container id="flow-rate">
      <Slider
        onChange={onChange}
        label={label}
        value={Math.round(props.value * 100 || 50)}
        suffix={'%'}
        min={20}
        max={175}
        step={5}
        name="flowRate"
        mandatory={props.mandatory}
      />
    </Container>
  );
};
