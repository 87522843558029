import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../components';

const Container = styled.div`
  margin: 20px 0;
  > div {
    display: flex;
    align-items: center;
  }
`;

const label =
  'I confirm that the machine is in a steady state; is externally cleaned; and that there are no plate(s) protruding.';

export const SteadyState = props => {
  const onChange = value => {
    const isValid = props.mandatory ? value : true;
    props.onChange('steadyState', value, isValid);
  };

  useEffect(() => props.onChange('steadyState', null, null), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="steady-state">
      <Checkbox
        name="steadyState"
        label={label}
        value={props.value}
        onChange={onChange}
        id="steady-state-input"
      />
    </Container>
  );
};
