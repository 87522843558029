import { IconX } from '@tabler/icons';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const DialogContainer = styled.div`
  background: var(--white);
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--neutrals-30);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;

  > div > button,
  > button {
    margin-top: 10px;
    padding: 10px 20px;
  }

  ${props =>
    props.fullWidth &&
    `
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: fit-content;
    margin: auto;
    transform: none;
    overflow: scroll;
  `}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  cursor: pointer;
`;

const ScrollContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CloseButton = props => (
  <CloseButtonContainer>
    <IconX onClick={props.onClickHandler} color="var(--neutrals-70)" />
  </CloseButtonContainer>
);

export const Dialog = props =>
  props.show ? <ActiveDialog {...props} /> : null;

const ActiveDialog = props => {
  const dialogContainer = useRef();

  const handleClick = e => {
    if (props.disableBackdropClick) {
      return;
    }
    props.onClose &&
      !dialogContainer.current.contains(e.target) &&
      props.onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Backdrop>
      <DialogContainer ref={dialogContainer} fullWidth={props.fullWidth}>
        <ScrollContainer>
          {props.children}
          <CloseButton onClickHandler={props.onClose} />
        </ScrollContainer>
      </DialogContainer>
    </Backdrop>
  );
};
