import React from 'react';
import styled from 'styled-components';
import { IconCheck } from '@tabler/icons';

const ErrorContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const Circle = styled.div`
position: absolute;
left: 0;
top: 0;
border-radius: 50px;
box-shadow: ${props =>
  props.blue
    ? '0px 0px 0 2px var(--blue-primary);'
    : '0px 0px 0 2px var(--earth-secondary);'}
background: var(--white);
width: 100%;
height: 100%;
`;

const Letter = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: var(--earth-secondary);
  font-weight: bold;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    width: 80%;
    height: 80%;
  }
`;

export const ResultIcon = props => {
  if (props.result === 'Ok') {
    return props.showOk ? (
      <ErrorContainer>
        <Circle blue />
        <Letter>
          <IconCheck stroke="3" color="var(--blue-primary)" />
        </Letter>
      </ErrorContainer>
    ) : null;
  } else {
    return (
      <ErrorContainer>
        <Circle />
        <Letter>{props.result[0]}</Letter>
      </ErrorContainer>
    );
  }
};
