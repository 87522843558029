import { configureStore } from '@reduxjs/toolkit';
import unitsReducer from './units';
import analysesReducer from './analyses';
import remindersReducer from './reminders';
import customersReducer from './customers';
import userReducer from './user';
import errorsReducer from './errors';
import { API } from '../api';

// const preloadedState = localStorage.getItem('reduxState')
//   ? JSON.parse(localStorage.getItem('reduxState'))
//   : {};

const createStore = apiSettings => {
  const api = new API(apiSettings);
  const store = configureStore({
    reducer: {
      units: unitsReducer,
      analyses: analysesReducer,
      reminders: remindersReducer,
      customers: customersReducer,
      user: userReducer,
      errors: errorsReducer,
    },
    // preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: api,
        },
        serializableCheck: false,
      }),
  });

  // store.subscribe(() => {
  //   localStorage.setItem('reduxState', JSON.stringify(store.getState()));
  // });

  return { store, api };
};

export default createStore;
