import React from 'react';

import {
  IconAlertCircle,
  IconCircleCheck,
  IconQuestionMark,
  IconRotateClockwise2,
  IconChevronUp,
  IconChevronDown,
} from '@tabler/icons';
import { formatDate, reportHasError } from '../../helpers';
import {
  StatusContainer,
  ReportDateContainer,
  ReminderContainer,
  ExpansionIcon,
  ButtonContainer,
  GroupUnitsLabel,
} from './styles';
import {
  ConditionalRender,
  RunAnalyticsButton,
  ViewProductButton,
} from '../../components';
import { differenceInDays, differenceInSeconds } from 'date-fns';

export const Status = ({ reports }) =>
  Array.isArray(reports) && reports.length > 0 ? (
    <StatusContainer error={reportHasError(reports[0])}>
      {reportHasError(reports[0]) ? (
        <IconAlertCircle size="30" color="var(--earth-secondary)" />
      ) : reports[0].hotSideResult === 'Ok' &&
        reports[0].coldSideResult === 'Ok' ? (
        <IconCircleCheck size="30" color="var(--blue-primary)" />
      ) : (
        <IconRotateClockwise2 size="30" color="var(--blue-primary)" />
      )}
    </StatusContainer>
  ) : (
    <StatusContainer error={null}>
      <IconQuestionMark size="30" color="var(--white)" />
    </StatusContainer>
  );

export const ReportDate = ({ reports }) => (
  <ReportDateContainer>
    <Status reports={reports} />
    {Array.isArray(reports) && reports.length > 0
      ? formatDate(reports[0].startTimestamp, true)
      : ''}
  </ReportDateContainer>
);

export const Reminder = ({ reminder }) => {
  const daysUntil = differenceInDays(new Date(reminder), new Date());
  const isInvalid =
    daysUntil === 0
      ? differenceInSeconds(new Date(reminder), new Date()) < 0
      : daysUntil < 0;
  return (
    <ReminderContainer invalid={isInvalid}>
      {reminder ? formatDate(reminder) : ''}
    </ReminderContainer>
  );
};

export const Expansion = props => (
  <ExpansionIcon>
    {props.expanded === props.index ? (
      <IconChevronUp size="15" />
    ) : (
      <IconChevronDown size="15" />
    )}
  </ExpansionIcon>
);

export const BodyButtons = props => (
  <ButtonContainer>
    <RunAnalyticsButton id={props.id} />
    <ViewProductButton id={props.id} />
  </ButtonContainer>
);

export const UnitsGroupHeader = ({ groupUnitsBy, index, unit, units }) => (
  <ConditionalRender
    predicate={
      groupUnitsBy &&
      (index === 0 || unit[groupUnitsBy] !== units[index - 1][groupUnitsBy])
    }>
    <GroupUnitsLabel>{unit[groupUnitsBy]}</GroupUnitsLabel>
  </ConditionalRender>
);
