import React from 'react';
import {
  ErrorType,
  RunAnalyticsButton,
  ViewProductButton,
  ResultIcon,
} from './../';
import { IconClock, IconHistory } from '@tabler/icons';

import {
  Head,
  TwoColumnFlex,
  PreviousCheck,
  Body,
  ButtonContainer,
  ReminderHeader,
  NoneContainer,
  HeaderItem,
} from './styles';
import { reportHasError } from '../../helpers';

import { format, formatDistanceToNow } from 'date-fns';
import { UnitIdentity } from '../unit-identity';

const getLastReportTimestamp = unit =>
  unit.reports.length > 0
    ? `Previous report
${format(new Date(unit.reports[0].startTimestamp), 'dd-MM-yyyy HH:mm')}`
    : 'No previous report';

const getTimeUntil = timeStamp =>
  formatDistanceToNow(new Date(timeStamp), {
    addSuffix: true,
  });

const sortByDate = (a, b) =>
  new Date(a.latestReport.startTimestamp) -
  new Date(b.latestReport.startTimestamp);

const NeedAnalysisItems = props => {
  const items = props.items
    .filter(u => reportHasError(u.latestReport))
    .sort(sortByDate);

  return (
    <>
      <HeaderItem>
        <h4>Needs attention</h4>
        <h4>({items.length})</h4>
      </HeaderItem>
      {items.length > 0 ? (
        items
          .filter((u, index) => index < 3)
          .map(u => (
            <li key={`${u.serialNumber} ${u.latestReport.startTimestamp}`}>
              <Head
                onClick={() =>
                  props.toggleExpanded(u.latestReport.startTimestamp)
                }>
                <UnitIdentity unit={u} />
                <TwoColumnFlex>
                  <ErrorType>
                    <ResultIcon result={u.latestReport.hotSideResult} showOk />
                    <ResultIcon result={u.latestReport.coldSideResult} showOk />
                  </ErrorType>
                  <ReminderHeader>
                    <IconHistory color="var(--neutrals-60)" size="20" />
                    <div>{getTimeUntil(u.latestReport.startTimestamp)}</div>
                  </ReminderHeader>
                </TwoColumnFlex>
              </Head>
              <Body expanded={props.expanded === u.latestReport.startTimestamp}>
                <PreviousCheck>
                  <IconClock size="15" />
                  {getLastReportTimestamp(u)}
                </PreviousCheck>
                <ButtonContainer>
                  <RunAnalyticsButton id={u.latestReport.heatExchangerId} />
                  <ViewProductButton id={u.latestReport.heatExchangerId} />
                </ButtonContainer>
              </Body>
            </li>
          ))
      ) : (
        <NoneContainer>{'None'}</NoneContainer>
      )}
    </>
  );
};

export default NeedAnalysisItems;
