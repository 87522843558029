import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ProtectedApp from './ProtectedApp';
import { Header, Footer, ShowCurrentCustomer, ErrorMessages } from './components';
import { Login } from './pages';
import { signIn } from './redux/user';
import useAuth, { Protected } from './hooks/useAuth';
import { MessageBar, MessageBarType, ProgressIndicator, createTheme, ThemeProvider } from '@fluentui/react'
import { initializeIcons } from '@fluentui/font-icons-mdl2';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const App = () => {

  const dispatch = useDispatch();
  initializeIcons();
  const { isAuthenticated } = useAuth();
  const [errorData, setErrorData] = useState('');
  const [percentComplete, setPercentComplete] = React.useState(0);

  const signedIn = useSelector(state => state.user.signedIn);

  useEffect(() => isAuthenticated && dispatch(signIn()), [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (errorData) {
      const intervalDelay = 100;
      const intervalIncrement = 0.01;

      const id = setInterval(() => {
        if (percentComplete >= 0.98) {
          setPercentComplete(0);
          setErrorData('');
        }
        setPercentComplete((intervalIncrement + percentComplete) % 1);

      }, intervalDelay);
      return () => {
        clearInterval(id);
        if (percentComplete >= 0.98) {
          dismissErrorHandler();
        }
      };
    }
  }, [errorData, percentComplete]);

  window.onunhandledrejection = function (e) {
    console.log('DETECTED ERROR', e.reason);
    setErrorData(e.reason);
    // setTimeout(
    //   () => {
    //     dismissErrorHandler()
    //   }, 10000);
  }
  const dismissErrorHandler = () => {
    setPercentComplete(0);
    setErrorData('');
  }

  return (

    <FullSize>
      {errorData &&
        <div style={{ zIndex: 100 }}>

          <MessageBar
            messageBarType={MessageBarType.error}
            dismissButtonAriaLabel='Close'
            onDismiss={dismissErrorHandler}
            dismissIconProps={{ iconName: 'Clear', styles: { root: { color: `#fff !important` } } }}
            styles={{
              root: {
                background: '#E84745',
                position: 'fixed',
                margin: 15,
                borderRadius: 5,
                padding: 5,
                paddingBottom: 0,
                width: '97vw'
              },
              icon: {
                color: '#fff !important'
              }
            }}
          >
            <span style={{ color: 'white' }}>{errorData}</span>
            <ProgressIndicator percentComplete={percentComplete}
              styles={{
                root: {
                  width: '97.5vw',
                  marginLeft: '-2.2vw',
                  height: 5
                },
                progressBar: {
                  background: '#a33231',
                  color: 'blue',
                  height: 5,
                  borderRadius: 5
                },
                progressTrack: {
                  background: 'none',
                  color: 'blue'
                }
              }} />
          </MessageBar>
        </div>
      }
      <Protected>
        <ShowCurrentCustomer />
      </Protected>
      <Responsive>
        <Container>
          <Protected>
            <Header />
          </Protected>
          {!signedIn && <Login errorData={errorData} />}
          <Protected>
            <ProtectedApp />
          </Protected>
        </Container>
      </Responsive>
      <Footer />
    </FullSize>
  );
};

const Responsive = styled.div`
  display: flex;
  margin: 0 auto;
  flex-grow: 1;
  @media (min-width: 320px) {
      width: 100vw;
  }
  @media (min-width: 480px) {
      width: 100vw;
  }
  @media (min-width: 576px) {
      width: 100vw;
  }
  @media (min-width: 768px) {
      width: 768px;
    }
  }
  @media (min-width: 1024px) {
      width: 1024px;
  }
`;

const FullSize = styled.div`
  display: flex;
  flex-direction: column;
`;

export default App;
