import React from 'react';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 320px;
  padding: 28px 0;
  margin: 56px 0 0 0;
  height: auto;
  background-color: var(--blue-primary);
  color: var(--neutrals-30);
  text-align: center;
`;

export const Footer = () => <FooterContainer>© Alfa Laval</FooterContainer>;
