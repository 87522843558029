import styled from 'styled-components';

export const MoreInfo = styled.div`
  margin-bottom: 39px;
  border: 1px solid var(--neutrals-30);
  border-radius: 10px;
  margin: 0;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--neutrals-10);

  @media (min-width: 768px) {
    border-radius: 0;
    border: none;
    width: unset;
    margin: 0;
  }
  > div {
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-grow: 1;
    > div {
      width: fit-content;
    }
  }
`;

export const MoreInfoHeader = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--neutrals-70);
  margin: 6px 0px;
`;

export const MoreInfoText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

export const AnalyticsReminderContainer = styled.div`
  background-color: var(--neutrals-10);
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-row: 2 span;
  justify-content: center;
  align-items: center;

  h4 {
    font-style: normal;
    font-weight: 500;
    color: var(--neutrals-70);
    margin: 10px 0;
  }
  input {
    font-weight: 400;
    line-height: 24px;
    color: var(--neutrals-60);
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    height: 38px;
    background: var(--white);
    border: 1px solid var(--neutrals-30);
    box-sizing: border-box;
    border-radius: 4px;
    flex-grow: 0;
    margin: 5px 0px;
  }

  .react-datepicker-wrapper {
    width: fit-content;
  }

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
    margin-top: 20px;
  }
`;

export const AnalyticsDetailsContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-top: 20px;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const EditButton = styled.div`
  background-color: var(--neutrals-10);
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  a:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutrals-70);
    text-decoration: underline;
    svg {
      margin-right: 5px;
    }
  }
`;
