import React from 'react';
import styled from 'styled-components';
import { IconCircleCheck, IconAlertCircle } from '@tabler/icons';
import { TwoColumnGrid, SmallHeader } from '../styles';

const DetectedContainer = styled.div`
  font-weight: 900;
  color: var(--earth-secondary);
  display: flex;
  align-items: center;
  svg {
    margin: 0 5px 0 0;
  }
}
`;

const Detected = () => (
  <DetectedContainer>
    <IconAlertCircle fill="var(--earth-secondary)" color="var(--white)" />
    Detected
  </DetectedContainer>
);

const NotDetectedContainer = styled.div`
  font-weight: 900;
  color: var(--blue-primary);
  display: flex;
  align-items: center;
  svg {
    margin: 0 5px 0 0;
  }
}
`;

const NotDetected = () => (
  <NotDetectedContainer>
    <IconCircleCheck fill="var(--blue-primary)" color="var(--white)" />
    Not detected
  </NotDetectedContainer>
);

const ResultHeader = styled.h4`
  margin-top: 20px;
  &:first-of-type {
    margin: 0;
  }
`;

export const IssuesFromReport = ({ report }) => {
  const { hotSideResult: hotSide, coldSideResult: coldSide, coldComment: cc, hotComment: hc } = report;
console.log('defects', hotSide, coldSide, cc, hc)
  return (
    <>
      <ResultHeader>Hot side</ResultHeader>
      <TwoColumnGrid>
        <SmallHeader>Clogging</SmallHeader>
        <SmallHeader>
          {hotSide === 'Clogging' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        <SmallHeader>Fouling</SmallHeader>
        <SmallHeader>
          {hotSide === 'Fouling' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        <SmallHeader>Maldistribution</SmallHeader>
        <SmallHeader>
          {hotSide === 'Maldistribution' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        <SmallHeader>Invalid image</SmallHeader>
        <SmallHeader>
          {hotSide === 'InvalidImage' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        {
          hc &&
          <>
            <SmallHeader>Comment</SmallHeader>
            <p style={{ marginTop: 10, maxWidth: 200 }}>{hc}</p>
          </>
        }
      </TwoColumnGrid>

      <ResultHeader>Cold side</ResultHeader>
      <TwoColumnGrid>
        <SmallHeader>Clogging</SmallHeader>
        <SmallHeader>
          {coldSide === 'Clogging' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        <SmallHeader>Fouling</SmallHeader>
        <SmallHeader>
          {coldSide === 'Fouling' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        <SmallHeader>Maldistribution</SmallHeader>
        <SmallHeader>
          {coldSide === 'Maldistribution' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        <SmallHeader>Invalid image</SmallHeader>
        <SmallHeader>
          {coldSide === 'InvalidImage' ? <Detected /> : <NotDetected />}
        </SmallHeader>
        {
          cc &&
          <>
            <SmallHeader>Comment</SmallHeader>
            <p style={{ marginTop: 10, maxWidth: 200 }}>{cc}</p>
          </>
        }
      </TwoColumnGrid>
    </>
  );
};
