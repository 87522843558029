import { addMonths } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DateAndTimePicker } from '../..';

export const Container = styled.div`
  background-color: var(--neutrals-10);
  padding: 20px;
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
    max-width: 400px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    color: var(--neutrals-70);
    margin: 0;
  }

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const SetReminderAfterAnalysis = props => {
  const onChange = value => props.onChange('reminder', value, true);

  useEffect(
    () => props.onChange('reminder', addMonths(new Date(), 6), true),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Container>
      <DateAndTimePicker
        onChange={onChange}
        label={'Set next reminder'}
        value={props.value}
      />
    </Container>
  );
};
