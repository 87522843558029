import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mapBy } from '../helpers';
import { reportToErrorQueue } from './errors';

export const fetchUnits = createAsyncThunk(
  'units/fetchUnits',
  async (_, { extra: API, dispatch }) =>
    API.getUnits().catch(
      reportToErrorQueue(dispatch, {
        executor: 'units',
        issue: 'get',
        payload: null,
      })
    )
);

export const addUnit = createAsyncThunk(
  'units/addUnit',
  async ({ unit, callback }, { extra: API, dispatch }) =>
    API.createUnit(unit)
      .then(response => {
        dispatch(getUnitImage(unit.id));
        return response;
      })
      .then(response => {
        callback && callback();
        return response;
      })
      .catch(
        reportToErrorQueue(dispatch, {
          executor: 'units',
          issue: 'create',
          payload: { ...unit },
        })
      )
);

export const editUnit = createAsyncThunk(
  'units/editUnit',
  async ({ unit, callback }, { extra: API, dispatch }) =>
    API.editUnit(unit.id, unit)
      .then(response => {
        dispatch(getUnitImage(unit.id));
        return response;
      })
      .then(response => {
        callback && callback();
        return response;
      })
      .catch(
        reportToErrorQueue(dispatch, {
          executor: 'units',
          issue: 'edit',
          payload: { ...unit },
        })
      )
);

export const removeUnit = createAsyncThunk(
  'units/removeUnit',
  async (heatExchangerId, { extra: API, dispatch }) =>
    API.deleteUnit(heatExchangerId).catch(
      reportToErrorQueue(dispatch, {
        executor: 'units',
        issue: 'delete',
        payload: { heatExchangerId },
      })
    )
);

export const getUnitImage = createAsyncThunk(
  'units/getUnitImage',
  async (id, { extra: API }) =>
    API.getUnitImage(id)
      .then(response => response.blob())
      .then(blob => ({ id, blob }))
);

export const unitsSlice = createSlice({
  name: 'units',
  initialState: {
    items: [],
    ids: [],
    fetched: false,
    currentState: { pending: null, success: null, error: null },
    images: {},
  },
  reducers: {
    addImage: (state, { payload }) => ({
      ...state,
      images: { ...state.images, [payload.id]: payload.image },
    }),
    resetCurrentState: state => ({
      ...state,
      currentState: { pending: null, success: null, error: null },
    }),
    moveUnit: (state, { payload }) => {
      const { oldIndex, newIndex } = payload;
      const unit = state.items[oldIndex];
      state.items.splice(oldIndex, 1);
      state.items.splice(newIndex, 0, unit);
    },
  },
  extraReducers: {
    [fetchUnits.fulfilled]: (state, { payload }) => ({
      ...state,
      items: [...payload.units],
      ids: [...payload.units.map(({ id }) => id)],
      byIds: mapBy(payload.units, 'id'),
      fetched: true,
    }),
    [addUnit.fulfilled]: (state, { payload }) => ({
      ...state,
      items: [...state.items, payload.unit],
      ids: [...state.ids, payload.unit.id],
      currentState: {
        ...state.currentState,
        pending: null,
        success: new Date().getTime(),
        error: null,
      },
    }),
    [addUnit.rejected]: state => ({
      ...state,
      currentState: { pending: null, success: null, error: true },
    }),
    [addUnit.pending]: (state, action) => ({
      ...state,
      currentState: {
        ...state.currentState,
        pending: true,
        success: null,
        error: null,
      },
    }),
    [editUnit.fulfilled]: (state, { payload }) => {
      const index = state.items.findIndex(u => u.id === payload.unit.id);
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          payload.unit,
          ...state.items.slice(index + 1, state.length),
        ],
        currentState: {
          ...state.currentState,
          pending: null,
          success: new Date().getTime(),
          error: null,
        },
      };
    },
    [editUnit.rejected]: state => ({
      ...state,
      currentState: { pending: null, success: null, error: true },
    }),
    [editUnit.pending]: (state, action) => ({
      ...state,
      currentState: {
        ...state.currentState,
        pending: true,
        success: null,
        error: null,
      },
    }),
    [removeUnit.fulfilled]: (state, action) => {
      const index = state.items.findIndex(u => u.id === action.payload);
      return {
        ...state,
        ids: [...state.ids.filter(id => id !== action.payload)],
        items: [
          ...state.items.slice(0, index),
          ...state.items.slice(index + 1, state.items.length),
        ],
        currentState: {
          ...state.currentState,
          pending: null,
          success: new Date().getTime(),
          error: null,
        },
      };
    },
    [removeUnit.rejected]: state => ({
      ...state,
      currentState: { pending: null, success: null, error: true },
    }),
    [removeUnit.pending]: (state, action) => ({
      ...state,
      currentState: {
        ...state.currentState,
        pending: true,
        success: null,
        error: null,
      },
    }),
    [getUnitImage.fulfilled]: (state, { payload }) => ({
      ...state,
      images: {
        ...state.images,
        [payload.id]: URL.createObjectURL(payload.blob),
      },
    }),
  },
});

export const { moveUnit, resetCurrentState, addImage } = unitsSlice.actions;
export default unitsSlice.reducer;
