import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import createStore from './redux/createStore';
import { Provider } from 'react-redux';
import { ScrollTop } from './components';

import 'bootstrap/dist/css/bootstrap.min.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import './index.css';
import { AuthProvider } from './hooks/useAuth';
import { MediaProvider } from './hooks/useMedia';
import { APIProvider } from './api';

const msalInstance = new PublicClientApplication(msalConfig);

const history = createBrowserHistory();
const { store, api } = createStore({ msalInstance });

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <AuthProvider>
      <APIProvider api={api}>
        <MediaProvider>
          <Router history={history}>
            <Provider store={store}>
              <ScrollTop />
              <App />
            </Provider>
          </Router>
        </MediaProvider>
      </APIProvider>
    </AuthProvider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
