import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ConditionalRender, LoadingSpinner } from '../';
import { IconPhoto } from '@tabler/icons';
import useAPI from './../../api';
import { useDispatch, useSelector } from 'react-redux';
import { addImage } from '../../redux/units';

const Container = styled.div`
  border-radius: 5px;
  overflow: hidden;
  width: fit-content;
  text-align: center;
  margin: auto;
  img {
    height: auto;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
`;

export const UnitImage = props => {
  const dispatch = useDispatch();
  const blob = useSelector(state => state.units.images[props.unit.id]);
  const [image, setImage] = useState(blob);
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPI();
  const elRef = useRef(null);
  useEffect(() => {
    if (typeof blob === 'undefined') {
      setIsLoading(true);
      api
        .getUnitImage(props.unit.id)
        .then(response => response.blob())
        .then(imageBlob => {
          const blob = URL.createObjectURL(imageBlob);
          dispatch(addImage({ id: props.unit.id, image: blob }));
          setImage(blob);
          setIsLoading(false);
        })
        .catch(err => {
          dispatch(addImage({ id: props.unit.id, image: null }));
          setIsLoading(false);
        });
      return () => {
        setImage();
        setIsLoading();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container ref={elRef} size={props.size}>
      <ConditionalRender predicate={!isLoading} fallback={<LoadingSpinner />}>
        <ConditionalRender
          predicate={!isLoading && image}
          fallback={
            <IconPhoto
              height="100%"
              width="100%"
              color="var(--neutrals-20)"
              stroke="1"
            />
          }>
          <img src={image} alt="unit" />
        </ConditionalRender>
      </ConditionalRender>
    </Container>
  );
};
