import React from 'react';
import { Head, Body } from '../styles';
import { IssuesFromReport } from './';

export const FaultReport = ({ report }) => (
  <div>
    <Head>
      <h3>Fault report</h3>
    </Head>
    <Body>
      <IssuesFromReport report={report} />
    </Body>
  </div>
);
