import React from 'react';
import { IconClock, IconBell } from '@tabler/icons';

import {
  Head,
  ReminderHeader,
  PreviousCheck,
  Body,
  ButtonContainer,
  NoneContainer,
  HeaderItem,
} from './styles';

import { format, formatDistanceToNow } from 'date-fns';
import { RunAnalyticsButton, ViewProductButton, UnitIdentity } from './../';

const getTimeUntil = timeStamp =>
  formatDistanceToNow(new Date(timeStamp), {
    addSuffix: true,
  });

const getLastReportTimestamp = unit =>
  unit.reports.length > 0
    ? `Previous report 
${format(new Date(unit.reports[0].startTimestamp), 'dd-MM-yyyy HH:mm')}`
    : 'No previous report';

const sortByDate = (a, b) =>
  new Date(a.reminder.timeStamp) - new Date(b.reminder.timeStamp);

const Reminders = props => {
  const items = props.items.sort(sortByDate);
  return (
    <>
      <HeaderItem>
        <h4>Reminders</h4>
        <h4>({items.length})</h4>
      </HeaderItem>
      {items.length > 0 ? (
        items
          .filter((r, index) => r.daysUntil < 7 || index < 3)
          .map(unit => (
            <li key={`${unit.id}`}>
              <Head onClick={() => props.toggleExpanded(`reminder-${unit.id}`)}>
                <UnitIdentity unit={unit} />
                <ReminderHeader>
                  <IconBell color="#ffbc00" />
                  <div>{getTimeUntil(unit.reminder.timeStamp)}</div>
                </ReminderHeader>
              </Head>
              <Body expanded={props.expanded === `reminder-${unit.id}`}>
                <PreviousCheck>
                  <IconClock size="15" />
                  {getLastReportTimestamp(unit)}
                </PreviousCheck>
                <ButtonContainer>
                  <RunAnalyticsButton id={unit.id} />
                  <ViewProductButton id={unit.id} />
                </ButtonContainer>
              </Body>
            </li>
          ))
      ) : (
        <NoneContainer>{'None'}</NoneContainer>
      )}
    </>
  );
};
export default Reminders;
