import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TextInput } from '..';
import { MinimalPresentationalUnit, SecondaryButton } from '../';
import { useHistory } from 'react-router-dom';
import { startAnalysis } from '../../redux/analyses';
import { getUnitsOfCurrentCustomer } from '../../redux/selectors';

const Container = styled.div`
  margin: 0 auto;

  button {
    max-width: 150px;
    margin-top: 5px;
  }

  @media (min-width: 576px) {
    grid-row: 2 span;
    grid-column: 2 span;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  @media (min-width: 768px) {
    grid-column: 4 span;
    grid-row: 1 span;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  @media (min-width: 1024px) {
    grid-column: 6 span;
  }
`;

const TopContainer = styled.div`
  background-color: #f5f3f2;
  border-radius: 0px 30px 0px 0px;
  padding: 30px;
  text-align: left;
  @media (min-width: 576px) {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border-radius: 0;
    height: 100%;
    box-sizing: border-box;
  }
`;

const BottomContainer = styled.div`
  background-color: var(--blue-primary);
  border-radius: 0px 0px 0px 30px;
  padding: 30px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  > p {
    color: var(--white);
  }

  @media (min-width: 576px) {
    border-radius: 0;
    height: 100%;
    box-sizing: border-box;
  }
`;

const FindContainer = styled.ul`
  position: relative;
  flex-basis: 100%;
`;

const MatchList = styled.ul`
  position: absolute;
  left: 0;
  background-color: var(--white);
  width: 100%;
  display: ${props => (props.showMatches ? 'block' : 'none')};
  li div.minimal-presentational-unit {
    border-bottom: 1px solid var(--neutrals-50);
    cursor: pointer;
    > div {
      grid-template-columns: 60px 1fr;
      @media (max-width: 479px) {
        grid-template-columns: 60px 1fr;
      }
    }
  }
`;

export const FindUnit = props => {
  const units = useSelector(getUnitsOfCurrentCustomer);
  const [matches, setMatches] = useState([]);
  const [showMatches, setShowMatches] = useState(false);
  const [query, setQuery] = useState('');
  const history = useHistory();
  const findContainer = useRef();
  const dispatch = useDispatch();

  const onChange = value => {
    setQuery(value);
    if (value === '') {
      setShowMatches(false);
      return;
    }
    setMatches(units.filter(u => u.serialNumber.includes(value)));
    setShowMatches(true);
  };

  const onMatchClicked = unit => dispatch(startAnalysis(unit.id));

  const handleClick = e =>
    !findContainer.current.contains(e.target) && setShowMatches(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <Container>
      <TopContainer>
        <h3>Run an analytics of your unit</h3>
      </TopContainer>
      <BottomContainer>
        <p>Enter your serial number here</p>
        <FindContainer ref={findContainer}>
          <TextInput
            onChange={onChange}
            exampleText="42567-22831"
            value={query}
          />
          <MatchList showMatches={showMatches}>
            {matches.map(unit => (
              <li key={`unit-${unit.id}`}>
                <MinimalPresentationalUnit
                  unit={unit}
                  onClickHandler={() => onMatchClicked(unit)}
                />
              </li>
            ))}
          </MatchList>
        </FindContainer>
        <p>Where can I find the serial number?</p>
        <SecondaryButton
          onClickHandler={() => history.push(`/add-unit/${query}`)}>
          Add unit
        </SecondaryButton>
      </BottomContainer>
    </Container>
  );
};
