import { IconInfoSquare } from '@tabler/icons';
import React from 'react';
import styled from 'styled-components';
import { ConditionalRender } from '../';
import { FormLabel } from './../';

const RadioButton = styled.li`
  margin: 10px 16px 10px 0;
  display: flex;
  align-items: center;
  ${props =>
    props.img &&
    `
[type=radio] { 
    opacity: 1;
    width: 10px;
    height: 10px;
    }
[type=radio]::focus {
  border-style: solid;
  border-color: red;
  border-width: 2px;
}
`};
  img {
    width: 75px;
    margin: 10px 16px 10px 0;
  }
  display: flex;
  flex-direction: row;
  input {
    margin-right: 10px;
  }
  #question-portConfigurationType:focus{
    border-style: solid;
  }
`;

const RadioGroup = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Label = styled.label`
  font-weight: 400;
  line-height: 24px;
  color: var(--neutrals-60);
  font-size: 16px;
`;

const Information = styled.div`
  font-size: 12px;
  padding-top: 5px;
  background-color: var(--neutrals-05);
  border-radius: 5px;
  padding: 5px;
  margin: 2px;

  p {
    display: contents;
    float: right
  }
`

const ImageContainer = styled.div`
  img {
    opacity: ${props => (props.checked ? '1' : '0.5')};
  }
`;

const Image = ({ option, checked, onChange }) => (
  <ImageContainer checked={checked}>
    <img
      src={`./../${option.img}`}
      alt={option.label}
      onClick={() => onChange(option.value)}
    />
  </ImageContainer>
);

export const RadioButtons = props => (
  <>
    {props.label && (
      <FormLabel>
        {props.mandatory ? `${props.label} *` : props.label}
        
        <Information className='hide-on-small-screen'>
          <IconInfoSquare style={{float: 'left'}} />
          <p>Click or use arrow keys to change selection. Hit <code>&lt;space&gt;</code> to activate first selection.</p>
        </Information>
      </FormLabel>
    )}
    <RadioGroup>
      {props.options.map((option, index) => (
        <RadioButton
          key={index}
          img={props.img}
          onClick={() => !option.img && props.onChange(option.value)}>
          <input
            type="radio"
            id={option.value === null ? 'null' : option.value.toString()}
            name={props.name}
            value={option.value}
            onChange={() => props.onChange(option.value)}
            checked={props.value === option.value}
          />
          <Label>{option.label}</Label>
          <ConditionalRender predicate={option.img}>
            <Image
              checked={props.value === option.value}
              option={option}
              onChange={props.onChange}
            />
          </ConditionalRender>
        </RadioButton>
      ))}
    </RadioGroup>
  </>
);
