import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyseListOfUnits, MainButton, Dialog } from '../../components';

import { createAnalysis, cancelAnalysis } from '../../redux/analyses';
import { getUnitsOfCurrentCustomer } from '../../redux/selectors';

export const AnalyseSingleUnit = props => {
  const [isDone, setIsDone] = useState(false);
  const [data, setData] = useState({});
  const { show, id } = useSelector(state => state.analyses);
  const dispatch = useDispatch();
  const unit = useSelector(getUnitsOfCurrentCustomer).find(u => u.id === id);

  const onComplete = (data, isDone) => {
    setData(data);
    setIsDone(isDone);
  };

  const onDone = arr => {
    arr.forEach(data => dispatch(createAnalysis(data)));
    onClose();
  };

  const onClose = () => dispatch(cancelAnalysis());

  return (
    <>
      <Dialog show={show} onClose={onClose} fullWidth>
        <AnalyseListOfUnits units={[unit]} onComplete={onComplete} />
        <MainButton disabled={!isDone
          && document.getElementById('upload-image-cold-error')?.hidden === true
          && document.getElementById('upload-image-hot-error')?.hidden === true} onClickHandler={() => onDone(data)}>
          Generate report
        </MainButton>
      </Dialog>
    </>
  );
};
