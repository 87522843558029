import React from 'react';
import { IconChevronLeft } from '@tabler/icons';
import { TextButton } from './../';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 5px 0;
  box-sizing: border-box;
  width: 100%;
  button a {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const BackButton = props => (
  <Container>
    <TextButton to={props.to}>
      <IconChevronLeft />
      {props.children}
    </TextButton>
  </Container>
);
