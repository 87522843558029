import React from 'react';
import { UnitOverview } from '../../components';
import { List } from './styles';
import styled from 'styled-components';
import { UnitsGroupHeader } from './columns';
import ListHeader from './ListHeader';

const Container = styled.div`
  margin-top: 24px;
`;

const listHeaderOptions = [
  {
    label: 'Heat exchanger',
    value: 'name',
  },
];

const BasicList = props => (
  <Container>
    <List>
      <ListHeader
        setSortAttr={props.setSortAttr}
        sortAttr={props.sortAttr}
        options={listHeaderOptions}
      />
      {props.units.map((unit, index) => (
        <React.Fragment key={unit.id}>
          <UnitsGroupHeader
            groupUnitsBy={props.groupUnitsBy}
            index={index}
            unit={unit}
            units={props.units}
          />
          <UnitOverview unit={unit} index={index} history={props.history} />
        </React.Fragment>
      ))}
    </List>
  </Container>
);

export default BasicList;
