import React from 'react';
import styled from 'styled-components';
import {
  IconPlus,
  IconList,
  IconStethoscope,
  IconHome,
  IconLogout,
} from '@tabler/icons';
import { TextButton } from './../';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

const ButtonList = styled.ul`
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid var(--neutrals-10);
  border-bottom: 1px solid var(--neutrals-10);

  li {
    list-style: none;
    display: flex;
    align-items: center;
    button {
      color: var(--blue-primary);
      margin: 5px;
      display: flex;
      padding: 0 5px;

      a {
        display: flex;
        align-items: center;
      }

      p {
        font-size: 16px;
      }

      svg {
        margin: 5px;
        width: 20px;
      }
    }
  }
`;

const DesktopMenu = props => {
  const { instance } = useMsal();
  return (
    <ButtonList>
      <li>
        <TextButton to="/home">
          <IconHome size="15" stroke="1" color={'var(--blue-primary)'} />
          <p>Home</p>
        </TextButton>
      </li>
      <li>
        <TextButton to="/add-unit">
          <IconPlus size="15" stroke="1" color={'var(--blue-primary)'} />
          <p>Add Unit</p>
        </TextButton>
      </li>
      <li>
        <TextButton to="/list-units">
          <IconList size="15" stroke="1" color={'var(--blue-primary)'} />
          <p>Show Units</p>
        </TextButton>
      </li>
      <li>
        <TextButton to="/analyse-units/step-1">
          <IconStethoscope size="15" stroke="1" color={'var(--blue-primary)'} />
          <p>Analyse Units</p>
        </TextButton>
      </li>
      <li>
        <TextButton
          onClickHandler={() =>
            instance.logoutRedirect(loginRequest).catch(console.error)
          }>
          <IconLogout size="15" stroke="1" color={'var(--blue-primary)'} />
          <p>Log out</p>
        </TextButton>
      </li>
    </ButtonList>
  );
};

export default DesktopMenu;
