import React from 'react';
import styled from 'styled-components';
import { FormLabel } from './../';

const Labels = styled.div`
  display: flex;
`;

const Input = styled.input`
  padding: 20px 0;
`;

export const Slider = props => (
  <div>
    <Labels>
      <FormLabel>
        {props.mandatory ? `${props.label} *` : props.label}
      </FormLabel>
      <FormLabel>{`${props.value}${props.suffix || ''}`}</FormLabel>
    </Labels>
    <Input
      type="range"
      name={props.name}
      min={props.min}
      max={props.max}
      step={props.step}
      value={props.value}
      onChange={e => props.onChange(Number(e.target.value))}
    />
  </div>
);
