import React from 'react';

import {
  Name,
  Brand,
  Type,
  SerialNumberInput,
  ConfigurationTheta,
  InletsOutlets,
  PlatePackSize,
  Location,
  UploadImage,
  Customer,
  Usage,
} from './form-components';
import { ConditionalRender } from '../../components';
import styled from 'styled-components';

const Header = styled.h2`
  padding: 10px;
`;

const Form = ({ onChange, unitInfo }) => (
  <>
    <Header>Technical information</Header>
    <Name
      id="question-name"
      value={unitInfo.name}
      onChange={onChange('name')}
      mandatory
    />
    <Brand
      id="question-brand"
      value={unitInfo.brand}
      onChange={onChange('brand')}
      mandatory
    />
    <Customer
      id="question-customerId"
      value={unitInfo.customerId}
      onChange={onChange('customerId')}
      mandatory
    />
    <Type
      id="question-portConfigurationType"
      value={unitInfo.portConfiguration.portConfigurationType}
      onChange={(value, isValid) => {
        if (value && value !== 'regular') {
          onChange('portConfiguration')(
            {
              s1: 'NoPort',
              s2: 'NoPort',
              s3: 'NoPort',
              s4: 'NoPort',

              t1: 'NoPort',
              t2: 'NoPort',
              t3: 'NoPort',
              t4: 'NoPort',
            },
            true,
            's1 s2 s3 s4 t1 t2 t3 t4'
          );
        }
        onChange('portConfiguration', 'portConfigurationType')(value, isValid);
      }}
      mandatory
    />
    <ConditionalRender
      predicate={
        unitInfo.portConfiguration.portConfigurationType === 'regular'
      }>
      <InletsOutlets
        id="question-portConfiguration"
        value={unitInfo.portConfiguration}
        onChange={onChange('portConfiguration')}
        mandatory
      />
    </ConditionalRender>
    <SerialNumberInput
      id="question-serialNumber"
      value={unitInfo.serialNumber}
      onChange={onChange('serialNumber')}
      mandatory
    />
    <ConfigurationTheta
      id="question-isMixedTheta"
      value={unitInfo.isMixedTheta}
      onChange={onChange('isMixedTheta')}
      mandatory
    />
    <Usage
      id="question-isHeating"
      value={unitInfo.isHeating}
      onChange={onChange('isHeating')}
      mandatory
    />
    <PlatePackSize
      id="question-sizeOfPlatePlack"
      value={unitInfo.sizeOfPlatePlack}
      onChange={onChange('sizeOfPlatePlack')}
      mandatory
    />
    <Location
      id="question-location"
      value={unitInfo.location}
      onChange={onChange('location')}
    />
    <UploadImage
      id="question-image"
      value={unitInfo.image}
      onChange={onChange('image')}
    />
  </>
);

export default Form;
